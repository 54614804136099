<main class="app-content">
    <div class="app-title">
       <div>
          <h1><i class="fa fa-code"></i> Analytics</h1>
       </div>
    </div>
    <!-- <div class="fa-3x">
      <i class="fa-solid fas fa-sync fa-spin"></i>
      <i class="fa-solid fas fa-sync fa-spin"></i>
      <i class="fa-solid fas fa-circle-notch fa-spin"></i>
      <i class="fa-solid fas fa-cog fa-spin"></i>
      <i class="fa-solid fas fa-cog fa-spin fa-spin-reverse"></i>
      <i class="fa-solid fas fa-spinner fa-spin-pulse"></i>
      <i class="fa-solid fas fa-spinner fa-spin-pulse fa-spin-reverse"></i>
    </div> -->
    <div class="card" *ngIf="this.pgtype == undefined || this.pgtype == ''">
      <div class="card-header">
        Select Company
      </div>
      <div class="card-body">
         <div class="box">
            <div class="container">
                <div class="row">
                     
                     <div class="boxh col-lg-3 col-md-3 col-sm-3 col-xs-12">      
                        <a href="javascript://" (click)="callMenu('Revolution-Prep');" routerLinkActive="active" routerLink="/DataAI/Revolution-Prep">          
                           <div class="box-part text-center">                          
                              <!-- <i class="fa fa-line-chart fa-3x" aria-hidden="true"></i> -->
                              <div class="title">
                                 <h4 class="text-dark">Revolution Prep</h4>
                              </div>                          
                              <!-- <div class="text">
                                 <span class="text-dark">Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.</span>
                              </div> -->
                              <a href="javascript://"><i class="fa fa-arrow-circle-right fa-3x" aria-hidden="true"></i></a>                        
                           </div>
                        </a> 
                     </div>	
                     <div class="boxh col-lg-3 col-md-3 col-sm-3 col-xs-12">      
                        <a href="javascript://" (click)="callMenu('Hippo-Education');" routerLinkActive="active" routerLink="/DataAI/Hippo-Education">          
                           <div class="box-part text-center">                          
                              <!-- <i class="fa fa-bar-chart fa-3x" aria-hidden="true"></i> -->
                              <div class="title">
                                 <h4 class="text-dark">Hippo Education</h4>
                              </div>                          
                              <!-- <div class="text">
                                 <span class="text-dark">Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.</span>
                              </div> -->
                              <a href="javascript://"><i class="fa fa-arrow-circle-right fa-3x" aria-hidden="true"></i></a>                          
                           </div>
                        </a> 
                     </div>	
                    
                     <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">                 
                       <div class="box-part text-center">                          
                                <i class="fa fa-facebook fa-3x" aria-hidden="true"></i>                          
                          <div class="title">
                             <h4>Facebook</h4>
                          </div>                          
                          <div class="text">
                             <span>Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.</span>
                          </div>                          
                          <a href="#">Learn More</a>                          
                        </div>
                    </div>	  -->
              
              </div>		
            </div>
        </div>
      </div>
    </div>
    
    <div class="row" *ngIf="this.pgtype != undefined && this.pgtype != ''">
       <div class="col-md-12">
          <div class="tile">
             <div class="">
               <div>
                  <div class="input-group mb-3">                        
                     <!-- <input id="typeahead-config" type="text" id="question" name="question" class="form-control" [(ngModel)]="question" [ngbTypeahead]="search" placeholder="Ask me Anything" /> -->
                     <input (keyup.enter)="filterByQues()" id="typeahead-http" type="text" class="form-control mx-sm-3" [class.is-invalid]="searchFailed" [(ngModel)]="question" [ngbTypeahead]="search" placeholder="Ask me Anything" />
                     <div class="input-group-append">
                        <button type="button" class="btn btn-primary" id="btnsrch" (click)="filterByQues()">Go</button>
                     </div>
                   </div>                   
               </div>
               <!-- objectKeys(queryOption.driver_dict).length > 0 -->
               <!-- <div class="row mt-2" *ngIf="queryOption?.driver_dict != undefined"> 
                  <div class="col-md-12">
                     <div class="tile">
                        <div class="tile-body">
                           <div class="row">
                              <div class="col-md-6"><strong>Recommended Questions</strong></div>
                              <div class="col-md-6" style="text-align:right;">
                                 
                              </div>
                           </div>
                           <div class="table-responsive">
                              <div class="gridcontainer" style="padding-top: 10px;">
                                 <h6 *ngFor="let rec of objectKeys(queryOption.driver_dict); let i = index;">                                    
                                    <span><a href="javascript://" (click)="recommend(queryOption.driver_dict[rec])">
                                       Q{{(i + 1)}}: {{queryOption.driver_dict[rec].question}}
                                    </a>
                                    </span>
                                 </h6>             
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> -->
                <!-- <form [formGroup]="queryForm" (ngSubmit)="onSubmit()">
                   
                    <h6>Q1: What is the 
                        <select formControlName="opt1">
                           <option *ngFor="let opt of selOpt.opt1" [ngValue]="opt.id">{{opt.name}}</option>
                        </select> &nbsp;&nbsp;
                        <select formControlName="opt2">                            
                           <option *ngFor="let opt of selOpt.opt2" [ngValue]="opt.id">{{opt.name}}</option>
                        </select>
                        for the                     
                        <select formControlName="opt3">                            
                           <option *ngFor="let opt of selOpt.opt3" [ngValue]="opt.id">{{opt.name}}</option>
                        </select>
                        ? &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-primary" id="btnsubmit" style="margin-top:10px;"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span> Submit</button>&nbsp;&nbsp;
                        <button *ngIf="loading" type="button" class="btn btn-primary" (click)="onSubmitCancel();" style="margin-top:10px;">Cancel</button>
                    </h6>
                </form> -->
                <!-- <form [formGroup]="queryForm" (ngSubmit)="filterReport()">
                    <h6>Q1: Show me a chart of 
                        <select formControlName="opt4">                         
                           <option *ngFor="let opt of selOpt.opt4" [ngValue]="opt.id">{{opt.name}}</option>
                        </select> 
                        by 
                        <select formControlName="opt5">
                            <option value="all">All</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">Augest</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select> 
                        for 
                        <select formControlName="opt6">
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                        </select>
                        ? &nbsp;&nbsp;&nbsp;
                        <button type="submit" class="btn btn-primary" id="btnsubmit" style="margin-top:10px;"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span> Submit</button>&nbsp;&nbsp;
                        <button *ngIf="loading" type="button" class="btn btn-primary" (click)="onSubmitCancel();" style="margin-top:10px;">Cancel</button>
                    </h6>
                </form> -->
                <!-- <form [formGroup]="queryForm" (ngSubmit)="onSubmit()">
                <textarea class="form-control" id="querytxt" name="querytxt" rows="3" formControlName="querytxt" required="required"></textarea>
                <button type="submit" class="btn btn-primary" id="btnsubmit" style="margin-top:10px;"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span> Submit</button>&nbsp;&nbsp;
                <button *ngIf="loading" type="button" class="btn btn-primary" (click)="onSubmitCancel();" style="margin-top:10px;">Cancel</button>
                </form> -->
                <div *ngIf="sqlTxt != ''" class="alert alert-success" role="alert" style="margin-bottom:0px; margin-top:10px;"><strong>Query:</strong> {{sqlTxt}}</div>
                <div *ngIf="errorMsg != ''" class="alert alert-danger" role="alert" style="margin-bottom:0px; margin-top:10px;">{{errorMsg}}</div>
             </div>
          </div>
       </div>
    </div>

    <div id="loader" class="fa-3x text-center text-black" *ngIf="loading">
      <i class="fa fa-spinner fa-spin fa-2x fa-fw"></i>
    </div>
    <div class="alert alert-info" role="alert" *ngIf="!loading && selSection == 'q1' && queryRes.length == 0">
      No Records Found
    </div>
    <div class="row" *ngIf="!loading && selSection == 'q1' && queryRes.length > 0">
      <div class="col-md-12">
         <div class="tile">
            <div class="tile-body">
               <ul ngbNav #nav="ngbNav" class="nav-tabs">
                  <li [ngbNavItem]="1" *ngIf="!queryOption.distribution">
                     <a ngbNavLink>List</a>
                     <ng-template ngbNavContent>
                        <div class="row">                 

                           <div class="col-md-9"><strong>Total:</strong> {{queryRes.length}} Rows</div>
                           <div class="col-md-3" style="text-align:right;">
                              <div class="input-group mb-3" *ngIf="this.f.opt3.value == 'brand'">                        
                                 <ng-multiselect-dropdown style="width: 200px;"
                                 [placeholder]="'Select Year'"
                                 [settings]="dropdownSettings"
                                 [data]="yearData"
                                 [(ngModel)]="yearSel"
                                 (onSelect)="onItemSelect($event)"
                                 (onSelectAll)="onSelectAll($event)"
                                 >
                                 </ng-multiselect-dropdown>
                                 <div class="input-group-append">
                                    <button type="button" class="btn btn-primary" id="btnsrch" (click)="filterRes()">Go</button>
                                 </div>
                               </div>                              
                              
                              <!-- <ul style="list-style-type:none;">
                                 <li class="dropdown">
                                    <a href="#" data-toggle="dropdown" aria-label="Open Profile Menu" style="text-decoration:none;">Export&nbsp; <i class="fa fa-sort-desc" style="margin-top:-5px;"></i></a>
                                    <ul class="dropdown-menu settings-menu dropdown-menu-right" style="width:150px;">
                                       <li><a class="dropdown-item" href="javascript://" (click)="exportD(confirm,'csv')">CSV</a></li>
                                       <li><a class="dropdown-item" href="javascript://" (click)="exportD(confirm,'xlsx')">XLSX</a></li>
                                    </ul>
                                 </li>
                              </ul> -->
                           </div>
                        </div>
                        <div class="table-responsive"> <!-- style="height:200px !important; " --> <!-- style="overflow:auto; height: calc(100vh - 225px);" -->
                           <div class="gridcontainer" >
                              <table class="table table-hover table-bordered table-sm" id="">
                                 <thead>
                                    <tr>
                                       <th scope="col" *ngFor="let head of _object.keys(queryRes[0]); let i = index">{{head}}</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr *ngFor="let qres of queryRes; index as i">
                                       <td *ngFor="let key of _object.keys(qres); index as j">{{ qres[key] }} </td>
                                    </tr>
                                 </tbody>
                              </table>                     
                           </div>
                        </div>
                     </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink>Chart</a>
                    <ng-template ngbNavContent>
                     <div class="row" *ngIf="!loading && selSection == 'q1' && queryRes.length > 0">
                        <div #containerRef class="col-md-12 resW">
                           <ngx-charts-bar-vertical *ngIf="chartType == 'bar_chart'"
                           (window:resize)="onResize()"
                           [view]="[containerRef.offsetWidth, 500]"
                           [scheme]="colorScheme"
                           [results]="chartData"
                           [gradient]="gradient"
                           [xAxis]="showXAxis"
                           [yAxis]="showYAxis"
                           [legend]="showLegend"
                           [showXAxisLabel]="showXAxisLabel"
                           [showYAxisLabel]="showYAxisLabel"
                           [xAxisLabel]="xAxisLabel"
                           [yAxisLabel]="yAxisLabel"
                           (select)="onSelect($event)">
                           <ng-template #tooltipTemplate let-model="model">
                              <div style="padding:5px; font-weight: 700; font-size: 14px;">
                                 <div>Name: {{ model.name }}</div>
                                 <div>Value: {{ model.value }}</div>
                                 <!-- <div  *ngIf="model.extra.percentage != ''">%: {{ model.extra.percentage }}</div> -->
                              </div>
                           </ng-template>
                           </ngx-charts-bar-vertical>
                           <ngx-charts-bar-vertical-2d *ngIf="chartType == 'grouped_bar_chart'"
                           (window:resize)="onResize()"
                           [view]="[containerRef.offsetWidth, 500]"
                           [scheme]="colorScheme"
                           [results]="chartData"
                           [gradient]="gradient"
                           [xAxis]="showXAxis"
                           [yAxis]="showYAxis"
                           [legend]="showLegend"
                           [showXAxisLabel]="showXAxisLabel"
                           [showYAxisLabel]="showYAxisLabel"
                           [xAxisLabel]="xAxisLabel"
                           [yAxisLabel]="yAxisLabel"
                           [animations]="animations"
                           (select)="onSelect($event)">
                           </ngx-charts-bar-vertical-2d>
                           <ngx-charts-pie-chart *ngIf="chartType == 'pie_chart'"
                           (window:resize)="onResize()"
                           [view]="[containerRef.offsetWidth, 500]"
                           [view]="view"
                           [scheme]="colorScheme"
                           [results]="chartData"
                           [gradient]="gradient"
                           [legend]="showLegend"
                           [labels]="showLabels"
                           [doughnut]="isDoughnut"
                           (select)="onSelect($event)"
                           >
                           </ngx-charts-pie-chart>
                        </div>
                        
                     </div>
                    </ng-template>
                  </li>
               </ul>
               
               <div [ngbNavOutlet]="nav" class="mt-2"></div>
               
            </div>
         </div>
      </div>
   </div>
   

   <div class="row" *ngIf="selSection == 'q2'">
      <div class="col-md-12">
         <div class="tile" style="padding: 2px;">
            <!-- <h3 class="tile-title">Overall Metrics - September 2020</h3> -->
            <div class="embed-responsive">
               <section style="display: none;" id="no-views" class="hidden">
                  <div class="container sidebar-container my-3">
                     <h5>No views found on the Tableau Server. Please ensure your user has permission to see the views.</h5>
                  </div>
               </section>
               <section class="bg-white content-wrapper">
                  <div id="loader" class="hidden text-center" style="padding: 30px;">
                     <i class="fa fa-spinner fa-spin fa-4x fa-fw"></i>
                  </div>
                  <div class="viz-wrapper py-3" id="viz-wrapper" style=" height: 850px;">
                     <div id="tableauViz" style="width: 100%;" class="h-100 d-inline-block"></div>
                  </div>
               </section>
            </div>
         </div>
      </div>
   </div>
   
   <!-- <google-chart id="columnChart" [data]="columnChart"></google-chart> containerRef.offsetHeight -->
   

   <div class="row" *ngIf="!loading && selSection == 'q1' && queryOption?.driver_dict != undefined">
      <h4 *ngIf="objectKeys(queryOption.driver_dict).length > 0"> Driver </h4>
      <div id="loader"  class="col-md-12 fa-3x text-center text-black" *ngIf="checkLoading() > 0">
         <i class="fa fa-spinner fa-spin fa-2x fa-fw"></i>
      </div>
      <div class="row mt-2 col-md-12" *ngIf="checkLoading() == 0">
         <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" style="width: 100%;">
            <ngb-panel id="ngb-panel-{{i}}" *ngFor="let rec of driverQueryRes; let i = index;">
               <ng-template ngbPanelHeader let-opened="opened">
                  <div class="d-flex align-items-center justify-content-between">
                    <!-- <h6 ngbPanelToggle class="m-0"><a ngbPanelToggle href="javascript://">{{queryOption.driver_dict[rec].question}}</a></h6> -->
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">{{rec.question}}</button>
                     <button ngbPanelToggle class="btn btn-link p-0">
                       <i *ngIf="opened && !driverLoading[i]" class="fa fa-chevron-up" aria-hidden="true"></i>
                       <i *ngIf="!opened && !driverLoading[i]" class="fa fa-chevron-down" aria-hidden="true"></i>
                       <i *ngIf="driverLoading[i]" class="fa-solid fas fa-circle-notch fa-spin" aria-hidden="true"></i>                    
                     </button>
                  </div>
                </ng-template>
               <ng-template ngbPanelContent>
   
                  <ul ngbNav #nav="ngbNav" class="nav-tabs">
                     <li [ngbNavItem]="1">
                       <a ngbNavLink>List</a>
                        <ng-template ngbNavContent>
                           <div class="table-responsive"> <!-- style="height:200px !important; " --> <!-- style="overflow:auto; height: calc(100vh - 225px);" -->
                              <div *ngIf="rec.error" class="alert alert-danger" role="alert" style="margin-bottom:0px; margin-top:10px;">{{rec.error}}</div>
                              <div class="gridcontainer"  *ngIf="rec.ldata && rec.ldata.length > 0">
                                 <table class="table table-hover table-bordered table-sm" id="">
                                    <thead>
                                       <tr>
                                          <th scope="col" *ngFor="let head of _object.keys(rec.ldata[0]); let i = index">{{head}}</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let qres of rec.ldata; index as i">
                                          <td *ngFor="let key of _object.keys(qres); index as j">{{ qres[key] }} </td>
                                       </tr>
                                    </tbody>
                                 </table>                     
                              </div>
                           </div>
                        </ng-template>
                     </li>
                     <li [ngbNavItem]="2">
                       <a ngbNavLink>Chart</a>
                        <ng-template ngbNavContent>
                           <div class="row" *ngIf="!loading && selSection == 'q1' && rec.cdata.length > 0">
                              <div #containerRef1 class="col-md-12 resW">
                                 <ngx-charts-bar-vertical *ngIf="rec.ctype == 'bar_chart'"
                                 (window:resize)="onResize()"
                                 [view]="[containerRef1.offsetWidth, 500]"
                                 [scheme]="colorScheme"
                                 [results]="rec.cdata"
                                 [gradient]="gradient"
                                 [xAxis]="showXAxis"
                                 [yAxis]="showYAxis"
                                 [legend]="showLegend"
                                 [showXAxisLabel]="showXAxisLabel"
                                 [showYAxisLabel]="showYAxisLabel"
                                 [xAxisLabel]="xAxisLabel"
                                 [yAxisLabel]="yAxisLabel"
                                 (select)="onSelect($event)">
                                 <ng-template #tooltipTemplate let-model="model">
                                    <div style="padding:5px; font-weight: 700; font-size: 14px;">
                                       <div>Name: {{ model.name }}</div>
                                       <div>Value: {{ model.value }}</div>
                                       <!-- <div  *ngIf="model.extra.percentage != ''">%: {{ model.extra.percentage }}</div> -->
                                    </div>
                                 </ng-template>
                                 </ngx-charts-bar-vertical>
                                 <ngx-charts-bar-vertical-2d *ngIf="rec.ctype == 'grouped_bar_chart'"
                                 (window:resize)="onResize()"
                                 [view]="[containerRef1.offsetWidth, 500]"
                                 [scheme]="colorScheme"
                                 [results]="rec.cdata"
                                 [gradient]="gradient"
                                 [xAxis]="showXAxis"
                                 [yAxis]="showYAxis"
                                 [legend]="showLegend"
                                 [showXAxisLabel]="showXAxisLabel"
                                 [showYAxisLabel]="showYAxisLabel"
                                 [xAxisLabel]="xAxisLabel"
                                 [yAxisLabel]="yAxisLabel"
                                 [animations]="animations"
                                 (select)="onSelect($event)">
                                 </ngx-charts-bar-vertical-2d>
                              </div>
                              
                           </div>
                          </ng-template>
                     </li>
                  </ul>
                  
                  <div [ngbNavOutlet]="nav" class="mt-2"></div>
   
                  
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
      </div>
      
   </div>

   <div class="row mt-2" *ngIf="!loading && rec_ques.length > 0">
      <div class="col-md-12">
         <div class="tile">
            <div class="tile-body">
               <div class="row">
                  <div class="col-md-6"><strong>Recommended Questions</strong></div>
                  <div class="col-md-6" style="text-align:right;">
                     
                  </div>
               </div>
               <div class="table-responsive">
                  <div class="gridcontainer" style="padding-top: 10px;">
                     <h6 *ngFor="let rques of rec_ques; let i = index;">
                        <span><a href="javascript://" (click)="recommend(rques)">
                           Q{{(i + 1)}}: {{rques}}
                        </a>
                        </span>
                     </h6>             
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- <div class="row" *ngIf="selSection == 'q1'">
      <div #containerRef2 class="col-md-4" *ngFor="let opt of selOpt.opt2; let i = index">
         {{opt.name}}
      </div>
   </div> -->
   
</main>