import { Pipe, PipeTransform } from '@angular/core';
import {
	HttpClient,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '@app/_services';

@Pipe({
  name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {
	user:any;
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService, // our service that provides us with the authorization token
  ) {

  	this.authService.user.subscribe(x => this.user = x);
  }

  /*async transform(src: string): Promise<any> {
    const headers = new HttpHeaders({'X-Auth-Token': this.user.token, 'X-Auth-Site': this.user.site_id});
    const imageBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
  }*/
  async transform(src: string): Promise<string> {
	  const headers = new HttpHeaders({'X-Auth-Token': this.user.token, 'X-Auth-Site': this.user.site_id});
	  const imageBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
	  const reader = new FileReader();
	  return new Promise((resolve, reject) => {
	    reader.onloadend = () => resolve(reader.result as string);
	    reader.readAsDataURL(imageBlob);
	  });
	}

}
