<!-- Header -->
<app-header *ngIf="showHeader" [headerObj]=headerObj></app-header> 
<!-- Sidebar -->
<app-sidebar *ngIf="showSidebar" [sidebarObj]=headerObj></app-sidebar>
<!-- Content -->
<router-outlet></router-outlet>











<!-- <div class="d-flex" id="wrapper"> 
    <app-sidebar *ngIf="showSidebar" [sidebarObj]=headerObj></app-sidebar>
    
    <div id="page-content-wrapper">
       <app-header *ngIf="showHeader" [headerObj]=headerObj></app-header> 
        
        <router-outlet></router-outlet>
        
    </div>
</div> -->
    

    


    <!--Thumbnail Modal -->
    <div class="modal fade" id="thumbnailModal" tabindex="-1" role="dialog" aria-labelledby="thumbnailModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="thumbnailModalLabel">Dashboards</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <section class="my-3">
                        <div id="views" class="container sidebar-container">
                            <div id="viewsList" class="row mb-3" class="close" data-dismiss="modal" aria-label="Close">
                            <!-- views thumbnails insert here -->
                            </div>
                        </div>
                    </section>
                    
                    <template id="viewThumbnail">
                        <div class="col-sm-6 col-md-5">
                            <div class="thumbnail text-center clickTarget" onclick="hiddenFrame();hiddenAll();">
                                <img width="100%" height="300px" class="image" />
                                <div class="name display-topleft"></div>
                                <div class="star__wrapper"><span class="star"></span></div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>

    <!-- Update Password Modal -->
    <div class="modal fade" id="updatePasswordModal" tabindex="-1" role="dialog" aria-labelledby="updatePasswordModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="updatePasswordModalLabel">Update Password</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="updatePassword1">Password</label>
                            <input type="password" autocomplete="new-password" class="form-control" id="updatePassword1" placeholder="New Password" onkeyup="vm.onUpdatePasswordInputChange()" onchange="vm.onUpdatePasswordInputChange()">
                        </div>
                        <div class="form-group">
                            <label for="updatePassword2">Confirm Password</label>
                            <input type="password" autocomplete="new-password" class="form-control" id="updatePassword2" placeholder="Confirm Password" onkeyup="vm.onUpdatePasswordInputChange()" onchange="vm.onUpdatePasswordInputChange()">
                        </div>
                    </form>

                    <div class="alert alert-warning hidden update-password-error" role="alert">
                        There was an error updating your password.
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" id="updatePasswordSaveButton" class="btn btn-primary" onclick="vm.onSavePasswordClick()">Save</button>
                </div>
            </div>
        </div>
    </div>


<ng-template #timeoutmodel let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-timeout-title">You Have Been Idle! </h4>
        <button type="button" class="close" aria-label="Close" #closebutton (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div>{{idleState}}</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="stay();">Stay</button>
        <!-- <button type="button" class="btn btn-secondary btn-outline-dark" (click)="logout()">Logout {{timerCount}}</button> -->
        <button class="btn btn-secondary btn-outline-dark" (click)="logout();">
            <i class="fa fa-sign-out" aria-hidden="true"></i> Logout 
        </button>
    </div>
</ng-template>  

<app-footer></app-footer>                
            
    

