import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterModule, ActivatedRoute} from "@angular/router";
import { environment } from '../../../environments/environment';
import { MetricsService } from '../../metrics.service';
import { CustomService } from '../../_services/custom.service';
import { AuthenticationService } from '@app/_services';

import { ContextMenuComponent } from 'ngx-contextmenu';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

	@Input() sidebarObj: any;

	loading = false;
    error: boolean = false;
    errorMsg = '';
    menuRes: any = []; 
    menuRSRes: any = [];
    _object = Object;
    showQryTxt: boolean = true; 
    initLoad: boolean = true;
    user: any;
	paramsSub:any;
	sourceId:number;
	companyType = 'Revolution-Prep';

    @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
	
	constructor(
		private http: HttpClient,
		private mService: MetricsService,
		private router: Router,
		private actRouter: ActivatedRoute,
		private cService: CustomService,		
		private authService: AuthenticationService
		//private cd: ChangeDetectorRef 
	) { 
		//this.cService.onLoadScript(); 
		this.authService.user.subscribe(x => this.user = x);
		this.mService.rsMenuVars = this.mService.    
		  invokeRSMenuFunction.subscribe((sitem:any) => {  
			this.menuRSRes = [];
			this.getSideMenu(sitem);  
			// if(sitem.type) {
			// 	this.getDSSideMenu(sitem);
			// }
		        
		}); 
	}

	ngOnInit(): void {
		console.log(this.sidebarObj);
		// if (this.mService.rsMenuVars==undefined) {    
		//   this.mService.rsMenuVars = this.mService.    
		//   invokeRSMenuFunction.subscribe((sitem:any) => {    
		//     this.getSideMenu(sitem);    
		//   });    
		// } 

		this.showQryTxt = true;
		// setTimeout (() => {
		// 	if(this.sidebarObj.showSqlMenu) {
		// 		this.getSideMenu('init');
		// 	}
		// }, 100);
		
	}

	showTable(row: any, stype: string) { 
		let str = this.router.url;
		if(str.includes("/Redshift/") == true) {
			this.router.navigate(['/Redshift/'+this.sidebarObj.params.id+'/'+stype+'/'+row.table_schema+'/'+row.table_name]);
		} else if(str.includes("/Mysql/") == true) {
			this.router.navigate(['/Mysql/'+this.sidebarObj.params.id+'/'+stype+'/'+row.table_name]);
		} else if(str.includes("/SFQuery/") == true) {
			this.router.navigate(['/SFQuery/'+stype+'/'+row.TABLE_NAME]);
		} else {
			this.router.navigate(['/SQLQuery/'+stype+'/'+row.table_schema+'/'+row.table_name]);
		}

		// if(this.sidebarObj.params.id) {
		// 	this.router.navigate(['/Redshift/'+this.sidebarObj.params.id+'/'+stype+'/'+row.table_schema+'/'+row.table_name]);
		// } else {
		// 	this.router.navigate(['/SQLQuery/'+stype+'/'+row.table_schema+'/'+row.table_name]);
		// }
		
		//this.router.navigateByUrl('/SQLQuery/'+stype+'/'+row.table_schema+'/'+row.table_name);
		/*this.router.navigate(['/SQLQuery/'+stype+'/'+row.table_schema+'/'+row.table_name]).then(
			page => { window.location.reload(); }
		);*/
		//row.stype = stype;
		//this.mService.onRSShowTable(row);
	}

	showRSTable(row: any, stype: string) { 
		this.router.navigate(['/Redshift/'+this.sourceId+'/'+stype+'/'+row.table_schema+'/'+row.table_name]);
		//this.router.navigateByUrl('/SQLQuery/'+stype+'/'+row.table_schema+'/'+row.table_name);
		/*this.router.navigate(['/SQLQuery/'+stype+'/'+row.table_schema+'/'+row.table_name]).then(
			page => { window.location.reload(); }
		);*/
		//row.stype = stype;
		//this.mService.onRSShowTable(row);
	}

	tmenu(menuId) {
  		var sections = document.querySelectorAll('.treeview');
  		sections.forEach( e => {
  			if(e.id != menuId)
	        	e.classList.remove("is-expanded");
	    });
  		document.querySelector('#'+menuId).classList.toggle('is-expanded');
	}

	/*showTable1(row: any) {
		this.showQryTxt = false;
		this.http.post<any>(environment.rsApiUrl, { 'tblschema':row.table_schema, 'tblname':row.table_name, 'ptype':'tablelist' }).subscribe(data => {
	        this.loading = false;
			if(data.status == 'success' ) {
	          this.menuRes = data.output;
	        } else {
	          this.errorMsg = data.error;
	        }
		});
		console.log(row);
	}*/

	getSideMenu(item:any) {
		//if(this.initLoad) {
			this.menuRes = [];
			this.loading = false;
			var listArr = ['init', 'Revolution-Prep', 'Hippo-Education'];
			if(item == 'init') {
				this.companyType = 'Revolution-Prep';
			} else {
				this.companyType = item;
			}
			var com = this.sidebarObj.params.id;
			if(listArr.indexOf(com) > -1) {
				this.loading = true;
				this.http.post<any>(environment.rsApiUrl, { 'ptype':'sidemenu', 'company': com}).subscribe(data => {
					this.loading = false;
					if(data.status == 'success' ) {
					  this.menuRes = data.output;
					} else {
					  this.errorMsg = data.error;
					}
					this.loading = false;
				});
				this.initLoad = false;
			}	      	
	    //}
		
	}

	getDSSideMenu(item:any) {
		var sid = '';
		let str = this.router.url;
		var pgname = '';
		if(str.includes("/Redshift/") == true || item.type == 'redshift') {
			pgname = 'rs.php';
		} else if(str.includes("/Mysql/") == true || item.type == 'mysql') {
			pgname = 'mysql.php';
		}
		if(this.sidebarObj.params.id === undefined) {
			sid = item.sid;
		} else {
			sid = this.sidebarObj.params.id;
		}
		//if(this.initLoad) {
			this.loading = true;
	      	this.http.post<any>(environment.ApiPath+pgname, { 'ptype':'sidemenu', 'sourceid': sid }).subscribe(data => {
		        this.loading = false;
				if(data.status == 'success' ) {
		          this.menuRSRes = data.output;
		        } else {
		          this.errorMsg = data.error;
		        }
		        this.loading = false;
			});
	      	this.initLoad = false;
	    //}
		
	}

	getSFSideMenu(item:any) {
		var sid = '';
		let str = this.router.url;
		var pgname = 'sf.php';
		
		//if(this.initLoad) {
			this.loading = true;
	      	this.http.post<any>(environment.ApiPath+pgname, { 'ptype':'sidemenu' }).subscribe(data => {
		        this.loading = false;
				if(data.status == 'success' ) {
		          this.menuRSRes = data.output;
		        } else {
		          this.errorMsg = data.error;
		        }
		        this.loading = false;
			});
	      	this.initLoad = false;
	    //}
		
	}

	// ngOnDestroy() {
	// 	this.paramsSub.unsubscribe();
	// }

}
