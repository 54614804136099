import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from '@app/_services';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    user:any;
    constructor(private authService: AuthenticationService, ) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //const token: string = localStorage.getItem('token');
        this.authService.user.subscribe(x => this.user = x);

        if (this.user) {
            request = request.clone({ headers: request.headers.set('X-Auth-Token', this.user.token) });
            request = request.clone({ headers: request.headers.set('X-Auth-Site', this.user.site_id) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //console.log('event--->>>', event);
                }
                return event;
            }));
    }
}