<!-- <video class="login-bg-video" autoplay muted loop>
  <source src="https://video.wixstatic.com/video/11062b_35e9c1a919df41df8b95c20340739349/1080p/mp4/file.mp4" type="video/mp4">
</video>  

<div class="login-form">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="login-logo-image-wrapper">
        </div>
        
        <p class="helper-text">&nbsp;<p>
        
            <div class="alert alert-danger" *ngIf="error">{{errorMsg}}</div>
       
        <div class="form-group">
            <input type="text" class="form-control" name="username" value="" placeholder="Username" formControlName="username" required="required">
        </div>
        <div class="form-group">
            <input type="password" class="form-control" name="password" placeholder="Password" formControlName="password" required="required">
        </div>
        
        <input type="hidden" name="tableau_server_url" value="https://us-east-1.online.tableau.com">
        <input type="hidden" name="tableau_server_site" value="zuar">
        
        <div class="form-group">
            <button type="submit" class="btn btn-primary btn-lg btn-block ">
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            Sign in</button>
        </div>

        <div class="powered-by">
            <span>Powered by <a href="https://www.datonique.com/">Datonique</a></span>
        </div>
    </form>
</div> -->


<section class="material-half-bg">
      <div class="cover"></div>
    </section>
    <section class="login-content">
      <div class="logo">
      <img src="assets/images/logo_small.png"  alt=""/> </div>
      <div class="login-box">
        <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <h3 class="login-head"><i class="fa fa-lg fa-fw fa-user"></i>SIGN IN</h3>
          <div class="form-group">
            <label class="control-label">USERNAME</label>
            <input class="form-control" type="text" name="username" value="" placeholder="Username" formControlName="username" required="required" autofocus>
          </div>
          <div class="form-group">
            <label class="control-label">PASSWORD</label>
            <input class="form-control" type="password" name="password" placeholder="Password" formControlName="password" required="required">
          </div>
          <!-- <div class="form-group">
            <div class="utility">
              <div class="animated-checkbox">
                <label>
                  <input type="checkbox"><span class="label-text">Stay Signed in</span>
                </label>
              </div>
              <p class="semibold-text mb-2"><a href="javascript://" data-toggle="flip" (click)="forgetFrom()">Forgot Password ?</a></p>
            </div>
          </div> -->
          <div class="form-group btn-container">
            <button type="submit" class="btn btn-primary btn-block"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span> <i class="fa fa-sign-in fa-lg fa-fw"></i>SIGN IN</button>
          </div>
        </form>
        <form class="forget-form" action="index.html">
          <h3 class="login-head"><i class="fa fa-lg fa-fw fa-lock"></i>Forgot Password ?</h3>
          <div class="form-group">
            <label class="control-label">EMAIL</label>
            <input class="form-control" type="text" placeholder="Email">
          </div>
          <div class="form-group btn-container">
            <button class="btn btn-primary btn-block"><i class="fa fa-unlock fa-lg fa-fw"></i>RESET</button>
          </div>
          <div class="form-group mt-3">
            <p class="semibold-text mb-0"><a href="javascript://" data-toggle="flip" (click)="forgetFrom()"><i class="fa fa-angle-left fa-fw"></i> Back to Login</a></p>
          </div>
        </form>
      </div>
    </section>
<script>
    /*let form = document.querySelector('form');
    if (window.location.search) {
        form.action = form.action + window.location.search;
    }

    const urlParams = new URLSearchParams(window.location.search);
    let loc = urlParams.get('location');
    if (loc) {
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', 'location');
        input.setAttribute('value', loc);
        form.appendChild(input);
    }
            let site = urlParams.get('site');
    if (site) {
        document.querySelector('.helper-text').innerHTML = 'Site: ' + site;
    }
    let username=urlParams.get('username');
    if (username) {
        let input = document.querySelector('input[name="username"]');
        input.setAttribute('value', username);
        input.setAttribute('type', 'hidden')

        let element = document.createElement('p')
        element.classList.add('form-control');
        element.innerHTML = username;

        input.parentNode.insertBefore(element, input);

        document.body.classList.add('embedded');
    }*/
</script>
