import { Component, NgModule, OnInit, VERSION, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Location } from "@angular/common";
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterModule, ActivatedRoute} from "@angular/router";
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

//import { HeaderComponent } from './layout/header/header.component';
//import { FooterComponent } from './layout/footer/footer.component';
//import { SidebarComponent } from './layout/sidebar/sidebar.component';
//import { HomeComponent } from './home/home.component';


import { environment } from '../environments/environment';
import { AuthenticationService } from './_services';
import { User, Role } from './_models';
import {MetricsService} from './metrics.service';
import { CustomService } from './_services/custom.service';
//import { startWith, tap, delay } from 'rxjs/operators';
//import { User } from './user';
//declare var tableau: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  @ViewChild("timeoutmodel") modalContent: TemplateRef<any>;
  modalReference: NgbModalRef;
  closeResult: string;
  //modalReference: any;

  //Idle Timeout
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  //sessData: any = (sData as any).default;

  //private home: HomeComponent;
  executeAction: EventEmitter<any> = new EventEmitter();
  name:string;
  expType:string;
  shareLink: string;
  shareTxt: string;
  currMetrics: string;
  metrics1 = {
    'Dashboard':'1_OverallDashboard',
    'LifetimeMetrics':'LifetimeMetrics',
    'MonthlyMetrics':'MonthlyMetrics',
    'DailyMetrics':'DailyMetrics',
    'MonthlySubs':'MonthlySubs',
    'ActiveUsers':'ActiveUsers',
    'NewSubs':'NewSubs',
    'TotalSubs':'TotalSubs',
    'EngagementDetail':'EngagementDetail',
    'Month1Retention':'Month1Retention'
  }

  showHead: boolean = false;
  isLogin: boolean = false;
  user: User;

  showHeader: boolean = true;
  showSidebar: boolean = true;

  headerObj:any = {'showTopIcons':true, 'showSideMenu':true, 'showSqlMenu':false, 'showAdminMenu':false, 'role':'User'};

  constructor(
    private authService: AuthenticationService, 
    private location: Location, 
    private router: Router, 
    private httpClient: HttpClient, 
    private ms: MetricsService,
    private idle: Idle, 
    private keepalive: Keepalive,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,    
    private cService: CustomService,
    private http: HttpClient,
  ) {   
    this.authService.user.subscribe(x => this.user = x);
    if(this.user && this.user.role == Role.Dataroom) {
      this.router.navigateByUrl('/Dataroom');
    }

    if (this.cService.loadVar==undefined) {    
      this.cService.loadVar = this.cService.    
      invokeOnloadScript.subscribe((name:string) => {    
        this.loadScripts();
      });    
    }   

    this.http.post<any>(environment.configApiUrl, { 'ptype':'getsession' }).subscribe(data => {        
      // sets an idle timeout of 10 seconds, for testing purposes.
      idle.setIdle(data.sessionTime);

      // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
      idle.setTimeout(data.sessionStay);
    });

    //this.authService.user.subscribe(x => this.user = x);
    // sets an idle timeout of 10 seconds, for testing purposes.
    //idle.setIdle(this.sessData.sessionTime);
        
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    //idle.setTimeout(this.sessData.sessionStay);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.modalReference.close();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.logout();
    });

    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        //console.log(this.idleState); 
        if(this.modalReference) {this.modalReference.close();}
        this.timeoutmodelopen();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      //console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();


    this.shareTxt = 'None';
    this.shareLink = 'javascript://';
    this.currMetrics = this.location.path().slice(1);
    //this.authService.user.subscribe(x => this.user = x);
    
    ms.changeEmitted$.subscribe(
        text => {
          if(this.currMetrics != "") {
            if((text in this.metrics1)) {
              //console.log('Metrics111: ',this.metrics1[text]);              
              this.shareLink = 'https://tableau.platopia.io/views/Hippo-AnalyticsDashboard/'+this.metrics1[text]+'?:embed=y';
              //this.shareLink = 'http://ec2-52-35-62-65.us-west-2.compute.amazonaws.com/views/Hippo-AnalyticsDashboard/'+this.metrics1[text]+'?:embed=y';
            } else {
              this.shareLink = '';
            }
          }  
        });

    this.authService.user.subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });
  }

  get isAdmin() {
    return this.user && this.user.role === Role.Admin;
  }

  ngOnInit() {
    this.loadScripts();
    
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          
          this.activatedRoute.firstChild.params.subscribe(params => {
            this.headerObj.params = params;
          });
          this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
          this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar !== false;
          this.headerObj.showTopIcons = this.activatedRoute.firstChild.snapshot.data.showTopIcons !== false;
          this.headerObj.showSideMenu = this.activatedRoute.firstChild.snapshot.data.showSideMenu !== false;
          if(this.activatedRoute.firstChild.snapshot.data.showSqlMenu != undefined) {
            this.headerObj.showSqlMenu = this.activatedRoute.firstChild.snapshot.data.showSqlMenu !== false;
          } else {
            this.headerObj.showSqlMenu = false;
          }
          if(this.activatedRoute.firstChild.snapshot.data.showAdminMenu != undefined) {
            this.headerObj.showAdminMenu = this.activatedRoute.firstChild.snapshot.data.showAdminMenu !== false;
          } else {
            this.headerObj.showAdminMenu = false;
          }
          if(this.activatedRoute.firstChild.snapshot.data.showProdMenu != undefined) {
            this.headerObj.showProdMenu = this.activatedRoute.firstChild.snapshot.data.showProdMenu !== false;
          } else {
            this.headerObj.showProdMenu = false;
          }
          if(this.activatedRoute.firstChild.snapshot.data.showDroomMenu != undefined) {
            this.headerObj.showDroomMenu = this.activatedRoute.firstChild.snapshot.data.showDroomMenu !== false;
          } else {
            this.headerObj.showDroomMenu = false;
          }
          if(this.activatedRoute.firstChild.snapshot.data.showPRMenu != undefined) {
            this.headerObj.showPRMenu = this.activatedRoute.firstChild.snapshot.data.showPRMenu !== false;
          } else {
            this.headerObj.showPRMenu = false;
          }
          if(this.activatedRoute.firstChild.snapshot.data.showDataAIMenu != undefined) {
            this.headerObj.showDataAIMenu = this.activatedRoute.firstChild.snapshot.data.showDataAIMenu !== false;
          } else {
            this.headerObj.showDataAIMenu = false;
          }
        }
      });

    this.isLogin = this.authService.isLoggedIn();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  stay() {
    this.modalReference.close();
    this.reset();
  }

  timeoutmodelopen() {
    this.modalReference = this.modalService.open(this.modalContent, {ariaLabelledBy: 'modal-timeout-title'});
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  tableausignin() {
    const headers = { "Content-Type":"application/json","Accept": "application/json" };
    const body = { "credentials": {"name": "rob@hippoeducation.com","password": "Rob_20!","site": {"contentUrl": ""}}};
    this.httpClient.post<any>('http://ec2-52-35-62-65.us-west-2.compute.amazonaws.com/api/3.7/auth/signin', body, { headers }).subscribe(data => {
      
    })
  }

  tableauProcess(type) {
      this.ms.MetricsType(type);
      if(type == 'pause') {
        document.querySelector('[data-dom-for="resume"]').classList.remove('hidden');
        document.querySelector('[data-dom-for="pause"]').classList.add('hidden');
      } else if(type == 'resume') {
        document.querySelector('[data-dom-for="pause"]').classList.remove('hidden');
        document.querySelector('[data-dom-for="resume"]').classList.add('hidden');
      }
      this.executeAction.emit();
    }

  toggleMenu() {
    //$("#wrapper").toggleClass("toggled");
  }

  logout(){
    this.modalReference.close();
    this.authService.logout();
    this.router.navigateByUrl('/Login');
  }

  loadScripts() {   
    // This array contains all the files/CDNs 
    const dynamicScripts = [ 
    'assets/js/main1.js'
    ]; 
    for (let i = 0; i < dynamicScripts.length; i++) { 
      const node = document.createElement('script'); 
      node.src = dynamicScripts[i]; 
      node.type = 'text/javascript'; 
      node.async = false; 
      document.getElementsByTagName('head')[0].appendChild(node); 
    } 
  } 
  
}
