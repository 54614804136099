import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2GoogleChartsModule  } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './home/home.component';

import {MetricsService} from './metrics.service';
import { LoginComponent } from './login/login.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { SqlqueryComponent } from './pages/sqlquery/sqlquery.component';

import { ContextMenuModule } from 'ngx-contextmenu';
import { SettingsComponent } from './admin/settings/settings.component';

import { ToastrModule } from 'ngx-toastr';
import { ReplacePipe } from './pipe/replace.pipe';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { KeyboardShortcutsModule }     from 'ng-keyboard-shortcuts';
import { AuthImagePipe } from './pipe/auth-image.pipe';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DataroomComponent } from './pages/dataroom/dataroom.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    SidebarComponent,
    SqlqueryComponent,
    SettingsComponent,
    ReplacePipe,
    AuthImagePipe,
    AnalyticsComponent,
    DataroomComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule, 
    NgbModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    ContextMenuModule.forRoot(),
    BrowserAnimationsModule, 
    ToastrModule.forRoot(), 
    NgWizardModule.forRoot(ngWizardConfig),
    KeyboardShortcutsModule.forRoot(),
    Ng2GoogleChartsModule,
    NgxChartsModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    MetricsService,
    {provide : LocationStrategy , useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
