<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<main class="app-content">
   <div class="app-title">
      <div>
         <h1><i class="fa fa-code"></i> SQL Query</h1>
      </div>
   </div>
   <div class="row" *ngIf="showQueryBox">
      <div class="col-md-12">
         <div class="tile">
            <div class="embed-responsive">
               <form [formGroup]="queryForm" (ngSubmit)="onSubmit()">
               <textarea class="form-control" id="querytxt" name="querytxt" rows="3" formControlName="querytxt" required="required"></textarea>
               <button type="submit" class="btn btn-primary" id="btnsubmit" style="margin-top:10px;"><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="loading"></span> Submit</button>&nbsp;&nbsp;
               <button *ngIf="loading" type="button" class="btn btn-primary" (click)="onSubmitCancel();" style="margin-top:10px;">Cancel</button>
               </form>
               <div *ngIf="sqlTxt != ''" class="alert alert-success" role="alert" style="margin-bottom:0px; margin-top:10px;"><strong>Query:</strong> {{sqlTxt}}</div>
               <div *ngIf="errorMsg != ''" class="alert alert-danger" role="alert" style="margin-bottom:0px; margin-top:10px;">{{errorMsg}}</div>
            </div>
         </div>
      </div>
   </div>

   <div id="loader" class="fa-3x text-center text-black" *ngIf="loading">
      <i class="fa fa-spinner fa-spin fa-2x fa-fw"></i>
   </div>

   <div class="row" *ngIf="queryRes.length > 0">
      <div class="col-md-12">
         <div class="tile">
            <div class="tile-body">
               <div class="row">
                  <div class="col-md-6"><strong>Total:</strong> {{queryRes.length}} Rows</div>
                  <div class="col-md-6" style="text-align:right;">
                     <ul style="list-style-type:none;">
                        <li class="dropdown">
                           <a href="#" data-toggle="dropdown" aria-label="Open Profile Menu" style="text-decoration:none;">Export&nbsp; <i class="fa fa-sort-desc" style="margin-top:-5px;"></i></a>
                           <ul class="dropdown-menu settings-menu dropdown-menu-right" style="width:150px;">
                              <li><a class="dropdown-item" href="javascript://" (click)="exportD(confirm,'csv')">CSV</a></li>
                              <li><a class="dropdown-item" href="javascript://" (click)="exportD(confirm,'xlsx')">XLSX</a></li>
                           </ul>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="table-responsive"> <!-- style="height:200px !important; " -->
                  <div class="gridcontainer" style="overflow:auto; height: calc(100vh - 225px);" >
                     <table class="table table-hover table-bordered table-sm" id="">
                        <thead>
                           <tr>
                              <th scope="col" *ngFor="let head of _object.keys(queryRes[0]); let i = index">{{head}}</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let qres of queryRes; index as i">
                              <td *ngFor="let key of _object.keys(qres); index as j">{{ qres[key] }} </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</main>


<ng-template #confirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Export</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to export data?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="exportData(exportType);modal.close('Export click')">Export</button>
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cancel</button>
  </div>
</ng-template>
