import { Component, NgModule, OnInit, OnDestroy, Input, ElementRef, ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { CommonChild, eventSubscriber } from '../common-child.interface';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterModule, ActivatedRoute} from "@angular/router";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import * as Tableau from 'tableau-api-js';
import { environment } from '../../environments/environment';
//import { HeaderComponent } from '../layout/header/header.component';
import { AuthenticationService } from '@app/_services';
import { MetricsService } from '../metrics.service';
import { User } from '../user';


declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
	@ViewChild('closebutton') closebutton;
	clickEventsubscription:Subscription;
	title = 'analytics';
	paramsSub:any;
	changepwd_form: FormGroup;
	chgPwdMsgshow: boolean = false;
	chgPwdMsg: string;
	tableauViz: any;
	currPath: any;
	currMetrics: any;
	currHost: any;  
	vizInit = false;
	shareLink: string;
  	shareTxt: string;
  	expType:string;
  	closeResult: string;
  	user: any;
	selMetrics = {'name':'1_OverallDashboard','hid':2, 'displayname':'Overall Dashboard', 'workbook':'Hippo-AnalyticsDashboard'};
	metrics = {
		'Dashboard':{'name':'1_OverallDashboard','hid':2, 'displayname':'Overall Dashboard', 'workbook':'Hippo-AnalyticsDashboard'},
		'LifetimeMetrics':{'name':'LifetimeMetrics','hid':3, 'displayname':'Lifetime Metrics', 'workbook':'Hippo-AnalyticsDashboard'},
		'MonthlyMetrics':{'name':'MonthlyMetrics','hid':4, 'displayname':'Monthly Metrics', 'workbook':'Hippo-AnalyticsDashboard'},
		'DailyMetrics':{'name':'DailyMetrics','hid':5, 'displayname':'Daily Metrics', 'workbook':'Hippo-AnalyticsDashboard'},
		'MonthlySubs':{'name':'MonthlySubs','hid':6, 'displayname':'Monthly Subs', 'workbook':'Hippo-AnalyticsDashboard'},
		'ActiveUsers':{'name':'ActiveUsers','hid':7, 'displayname':'Active Users', 'workbook':'Hippo-AnalyticsDashboard'},
		'NewSubs':{'name':'NewSubs','hid':8, 'displayname':'New Subs', 'workbook':'Hippo-AnalyticsDashboard'},
		'TotalSubs':{'name':'TotalSubs','hid':9, 'displayname':'Total Subs', 'workbook':'Hippo-AnalyticsDashboard'},
		'EngagementDetail':{'name':'EngagementDetail','hid':3, 'displayname':'Engagement', 'workbook':'Hippo-AnalyticsDashboard'},
		'Month1Retention':{'name':'Month1Retention','hid':4, 'displayname':'Month 1 Retention', 'workbook':'Hippo-AnalyticsDashboard'},

		'SubsbyProductTable':{'name':'SubsbyProduct-Table','hid':4, 'displayname':'Subs by Product Table', 'workbook':'Hippo-AnalyticsDashboard'},
		'SubsbyProductChart':{'name':'SubsbyProduct-Chart','hid':4, 'displayname':'Subs by Product Chart', 'workbook':'Hippo-AnalyticsDashboard'},
		'ChurnRateTable':{'name':'ChurnRate-Table','hid':4, 'displayname':'Churn Rate Table', 'workbook':'Hippo-AnalyticsDashboard'},
		'ChurnRatebyYear':{'name':'ChurnRatebyYear','hid':4, 'displayname':'Churn Rate by Year', 'workbook':'Hippo-AnalyticsDashboard'},
		'ChurnRatebyMonth':{'name':'ChurnRatebyMonth','hid':4, 'displayname':'Churn Rate by Month', 'workbook':'Hippo-AnalyticsDashboard'},
		'FreetoPaidConversion':{'name':'FreetoPaidConversion','hid':4, 'displayname':'Free to Paid Conversion', 'workbook':'Hippo-AnalyticsDashboard'},

		'Glossary': { 'name': 'Glossary', 'hid': 4, 'displayname': 'Glossary', 'workbook': 'Hippo-Analyses' },
		'LTVLifetimes':{'name':'LTVLifetimes','hid':4, 'displayname':'LTV/Lifetimes', 'workbook':'Hippo-Analyses'},
		'Lifetime-Metrics':{'name':'LifetimeMetrics','hid':4, 'displayname':'Lifetime Metrics', 'workbook':'Hippo-Analyses'},
		'Conversion-Rates':{'name':'FreetoPaidConversion','hid':4, 'displayname':'Conversion Rates', 'workbook':'Hippo-Analyses'},
		'Monthly-Metrics':{'name':'MonthlyMetrics','hid':4, 'displayname':'Monthly Metrics', 'workbook':'Hippo-Analyses'},
		'SubsbyProduct-Table':{'name':'SubsbyProduct-Table','hid':4, 'displayname':'Customers by Vertical', 'workbook':'Hippo-Analyses'},
		'SubsbyCountry':{'name':'SubsbyCountry','hid':4, 'displayname':'Customers by Geography', 'workbook':'Hippo-Analyses'},
		'SubsbyProfession':{'name':'SubsbyProfession','hid':4, 'displayname':'Customers by Medical Profession', 'workbook':'Hippo-Analyses'},
		'ChurnRatebyMonth-Table':{'name':'ChurnRatebyMonth-Table','hid':4, 'displayname':'Churn', 'workbook':'Hippo-Analyses'},

		'AAPASalesTransaction':{'name':'AAPASalesTransaction','hid':4, 'displayname':'AAPA Sales Transactions Report', 'workbook':'AAPASalesTransactionReport'},
		'AAPAGPSAQuiz':{'name':'AAPAGPSAQuiz','hid':4, 'displayname':'AAPA GP SA Quiz Report', 'workbook':'AAPAGPSAQuizReport'},
		'AAPAQbankQuiz':{'name':'AAPAQbankQuiz','hid':4, 'displayname':'AAPA Qbank Quiz Report', 'workbook':'AAPAQbankQuizReport'},
		'AAPAPCRapQuiz':{'name':'AAPAPCRapQuiz','hid':4, 'displayname':'AAPA Primary Care RAP Quiz Report', 'workbook':'AAPAPrimaryCareRAPQuizReport'},
	}

	@Input() showHeader: boolean;
	
  	constructor(
  		private titleService: Title, 
  		private authService: AuthenticationService, 
  		private location: Location, 
  		private router: Router, 
  		private ms: MetricsService, 
  		private el: ElementRef, 
  		private modalService: NgbModal,
  		private fb: FormBuilder,
  		private http: HttpClient,
  		private activatedRoute: ActivatedRoute
  	) { 

  		/*ms.metricsType$.subscribe((res)=>this.expType=res); 
	    this.clickEventsubscription = this.ms.getReportEvent().subscribe(()=>{
			this.executeActions();
		})*/

  		//ms.metricsType$.subscribe((res)=>this.expType=res); 
  		this.authService.user.subscribe(x => this.user = x);
  		this.executeAction = this.executeAction.bind(this);
        //eventSubscriber(headerComponent.executeAction, this.executeAction);

        /*this.paramsSub = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
        	if("url" in event) {
        		
        	}        	
        });	 */   

  		this.paramsSub = this.router.events.subscribe((event: Event) => {
	        if (event instanceof NavigationStart) {
	           if (this.tableauViz) { // If a viz object exists, delete it.
			        this.tableauViz.dispose();
			    }
	        }

	        if (event instanceof NavigationEnd) {
	            if("url" in event) {
	            	if(event.url == '/Partner-Reporting') {
	        			event.url = '/Partner-Reporting/AAPASalesTransaction';
	        		}
	            	if(event.url == '/Product') {
	        			event.url = '/Product/SubsbyProductTable';
	        		}
	        		if(event.url == '/Home') {
	        			event.url = '/Home/Dashboard';
	        		}
	        		if(event.url == '/Dataroom') {
	        			event.url = '/Dataroom/Lifetime-Metrics';
	        		}
	            	this.currMetrics = event.url.slice(1);
        			this.currMetrics = this.currMetrics.replace('Partner-Reporting/','');
        			this.currMetrics = this.currMetrics.replace('Product/','');
        			this.currMetrics = this.currMetrics.replace('Home/','');
        			this.currMetrics = this.currMetrics.replace('Dataroom/','');
        			if(this.currMetrics == '')  {
				  		this.currMetrics = 'Dashboard';
				  	}
				  	this.onLoadInit();
        		}
	        }

	        if (event instanceof NavigationError) {
	            // Hide loading indicator

	            // Present error to user
	            console.log(event.error);
	        }
	    });

	    //this.currPath = this.router.url.slice(1);
	    /*this.currPath = this.location.path().slice(1);
	    this.currMetrics = this.currMetrics.slice(1);
	  	if(this.currMetrics == '')  {
	  		this.currMetrics = 'Dashboard';
	  	}*/
  	}

	public setTitle( newTitle: string) {
		this.titleService.setTitle( newTitle );
	}
  	

  	public initViz(t): void {
  		this.ms.emitChange(this.currMetrics);

  		if(this.currMetrics != "") {
            if((this.currMetrics in this.metrics)) {
              this.shareLink = window.location.href;
            } else {
              this.shareLink = '';
            }
        }  
		
		let mloader = this.el.nativeElement.querySelector("#loader");
  		mloader.classList.remove('d-none'); 
		let mview = this.el.nativeElement.querySelector("#viz-wrapper");
		mview.classList.add('d-none'); 
		var workbook = 'Hippo-AnalyticsDashboard';
		if("workbook" in this.selMetrics) {
			//workbook = 'Hippo-Analyses';
			workbook = this.selMetrics.workbook;
		}

		const containerDiv = document.getElementById('tableauViz');
		
		const vizUrl = environment.tableauUrl+'/trusted/'+t+'/views/'+workbook+'/'+this.selMetrics.name+'?:size=1232,850&:embed=y&:showVizHome=n&:bootstrapWhenNotified=y&:tabs=n&:toolbar=n&:apiID=host'+this.selMetrics.hid;
		const options = {
			hideTabs: true,
			width: '100%',
			height: '850px',
			onFirstInteractive: function() {
				mloader.classList.add('d-none'); 
				mview.classList.remove('d-none');
				// The viz is now ready and can be safely used.
			}
		};

		if (this.tableauViz) { // If a viz object exists, delete it.
			this.tableauViz.dispose();
		}

		//this.tableauViz = new tableau.Viz(placeholderDiv, url, options);	
		this.tableauViz = new Tableau.Viz(containerDiv, vizUrl, options);
    }

    onLoadInit(): void {
		
		this.selMetrics = this.metrics[this.currMetrics];
		if(!("name" in this.selMetrics)) {
			this.selMetrics = {'name':'1_OverallDashboard','hid':2, 'displayname':'Overall Dashboard', 'workbook':'Hippo-AnalyticsDashboard'};
		}
		this.setTitle( 'Analytics - '+this.selMetrics.displayname );
		if(this.currMetrics == 'LifetimeMetrics' || this.currMetrics == 'MonthlyMetrics' || this.currMetrics == 'DailyMetrics' || this.currMetrics == 'MonthlySubs') {
			$('#submenu1').addClass('show');
		}
		
		/*document.querySelectorAll('.vizbutton').forEach(buttonEl => {
		//if (buttonEl.dataset['domFor'] !== 'resume') {
			buttonEl.classList.remove('hidden');
		//}
		});*/
		//document.querySelector('[data-dom-for="resume"]').classList.add('hidden');		

		this.http.post<any>(environment.apiUrl, { 'username': this.user.name,'ptype':'getTicket' }).subscribe(data => {
        	if(data != -1) {
        		this.initViz(data);
        	}
		});
	}

	ngOnInit(): void {
		$( document ).ready(function() {
		    $("[data-toggle='tooltip']").tooltip();
		});
	}

	copyText(val: string){
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}

	executeAction() {

		//alert('test');

	}

	executeActions(type) {
		this.expType = type;
        if(this.expType == 'pdf') {
        	this.tableauViz.showExportPDFDialog();
        } else if(this.expType == 'image') {
        	this.tableauViz.showExportImageDialog();
        } else if(this.expType == 'loaddata') {
        	this.tableauViz.showExportDataDialog();
        } else if(this.expType == 'crosstab') {
        	this.tableauViz.showExportCrossTabDialog();
        } else if(this.expType == 'undo') {
        	return this.tableauViz.undoAsync().then(function () {
                console.log('viz undo');
            });
        } else if(this.expType == 'refresh') {
        	return this.tableauViz.refreshDataAsync().then(function () {
                console.log('viz data refresh');
            });
        } else if(this.expType == 'revert') {
        	return this.tableauViz.revertAllAsync().then(function () {
                console.log('viz revert');
            });
        } else if(this.expType == 'redo') {
        	return this.tableauViz.redoAsync().then(function () {
                console.log('viz redo');
            });
        } else if(this.expType == 'pause') {
        	return this.tableauViz.pauseAutomaticUpdatesAsync().then(function () {
                console.log('viz paused');
                //document.querySelector('[data-dom-for="resume"]').classList.remove('hidden');
                //document.querySelector('[data-dom-for="pause"]').classList.add('hidden');
            });
        } else if(this.expType == 'resume') {
        	return this.tableauViz.resumeAutomaticUpdatesAsync().then(function () {
                console.log('viz resumed');
                //document.querySelector('[data-dom-for="pause"]').classList.remove('hidden');
               // document.querySelector('[data-dom-for="resume"]').classList.add('hidden');
            });
        }
        
    }

    tableauProcess(type) {
      //this.ms.MetricsType(type);
      this.executeActions(type);
      if(type == 'pause') {
        document.querySelector('#resume').classList.remove('d-none');
        document.querySelector('#pause').classList.add('d-none');
      } else if(type == 'resume') {
        document.querySelector('#pause').classList.remove('d-none');
        document.querySelector('#resume').classList.add('d-none');
      }
      //this.executeAction.emit();
    }

	toggleMenu() {
		$("#wrapper").toggleClass("toggled");
	}

	exportToPDF() {
		this.tableauViz.showExportPDFDialog();
	}

	logout(){
		this.authService.logout();
		this.router.navigateByUrl('/Login');
	}

	open(content) {
		this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			//this.closeResult = `Dismissed`;
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return  `with: ${reason}`;
		}
	}

	get f() { return this.changepwd_form.controls; }

	submitForm() {
		// stop here if form is invalid
        if (this.changepwd_form.invalid) {
            return;
        }
        
        this.http.post<any>(environment.apiUrl, { 'userid': this.user.credentials.user.id, 'newpassword':this.f.newpassword1.value, 'ptype':'changepassword' }).subscribe(data => {
        	this.chgPwdMsgshow = true;
			this.chgPwdMsg = "Password changed successfully";
		    //this.closebutton.nativeElement.click();
		    this.changepwd_form.reset();
		});
	};

	ngOnDestroy() {
		this.paramsSub.unsubscribe();
	}



}
