import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SqlqueryComponent } from './pages/sqlquery/sqlquery.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component'; 
import { DataroomComponent } from './pages/dataroom/dataroom.component';   
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';  

import { AuthGuard } from './_helpers';
import { Role } from './_models';


const routes: Routes = [
  { path: '', redirectTo: '/Home/Dashboard', pathMatch: 'full' },
  //{ path: '', pathMatch: 'full', redirectTo: 'Login'},
  { path: 'Login', component: LoginComponent, data: { showHeader: false, showSidebar: false } },
  { path: 'Home', component: HomeComponent,
    children: [
      { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
      { path: 'Dashboard', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'LifetimeMetrics', component: HomeComponent },
      { path: 'MonthlyMetrics', component: HomeComponent },
      { path: 'DailyMetrics', component: HomeComponent },
      { path: 'MonthlySubs', component: HomeComponent },
      { path: 'ActiveUsers', component: HomeComponent },
      { path: 'NewSubs', component: HomeComponent },
      { path: 'TotalSubs', component: HomeComponent },
      { path: 'EngagementDetail', component: HomeComponent },
      { path: 'Month1Retention', component: HomeComponent }
    ], canActivate: [AuthGuard]
  },
  /*{ path: 'Dashboard', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'LifetimeMetrics', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'MonthlyMetrics', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'DailyMetrics', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'MonthlySubs', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'ActiveUsers', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'NewSubs', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'TotalSubs', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'EngagementDetail', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'Month1Retention', component: HomeComponent, canActivate: [AuthGuard] },*/
  { path: 'SQLQuery/:id', component: SqlqueryComponent, canActivate: [AuthGuard], data: { showTopIcons: false, showSideMenu: false, showSqlMenu: true } },
  { path: 'SQLQuery/:id/:type/:schema/:table', component: SqlqueryComponent, canActivate: [AuthGuard], data: { showTopIcons: false, showSideMenu: false, showSqlMenu: true } },
  { path: 'DataAI', component: AnalyticsComponent, canActivate: [AuthGuard], data: { showTopIcons: false, showSideMenu: false, showDataAIMenu: true } },
  { path: 'DataAI/:id', component: AnalyticsComponent, canActivate: [AuthGuard], data: { showTopIcons: false, showSideMenu: false, showDataAIMenu: true } },
  //{ path: 'Admin/Settings', component: SettingsComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }},
  { path: 'Admin', component: SettingsComponent,
    children: [
      { path: '', redirectTo: 'Settings', pathMatch: 'full' },
      { path: 'Settings', component: SettingsComponent },
      { path: 'Users', component: SettingsComponent },
      { path: 'Groups', component: SettingsComponent },
      { path: 'Projects', component: SettingsComponent },
      { path: 'Workbooks/:id', component: SettingsComponent },
      { path: 'Views/:id', component: SettingsComponent }
    ], canActivate: [AuthGuard], data: {showTopIcons: false, showSideMenu: false, showSqlMenu: false, showAdminMenu: true, roles: [Role.Admin] }
  },
  { path: 'Product', component: HomeComponent,
    children: [
      { path: '', redirectTo: 'SubsbyProductTable', pathMatch: 'full' },
      { path: 'SubsbyProductTable', component: HomeComponent },
      { path: 'SubsbyProductChart', component: HomeComponent },
      { path: 'ChurnRateTable', component: HomeComponent },
      { path: 'ChurnRatebyYear', component: HomeComponent },
      { path: 'ChurnRatebyMonth', component: HomeComponent },
      { path: 'FreetoPaidConversion', component: HomeComponent }
    ], canActivate: [AuthGuard], data: { showSideMenu: false, showProdMenu: true , roles: [Role.Admin, Role.User]}
  },
  { path: 'Partner-Reporting', component: HomeComponent,
    children: [
      { path: '', redirectTo: 'AAPASalesTransaction', pathMatch: 'full' },
      { path: 'AAPASalesTransaction', component: HomeComponent },
      { path: 'AAPAGPSAQuiz', component: HomeComponent },
      { path: 'AAPAQbankQuiz', component: HomeComponent },
      { path: 'AAPAPCRapQuiz', component: HomeComponent }
    ], canActivate: [AuthGuard], data: { showSideMenu: false, showPRMenu: true , roles: [Role.Admin, Role.User]}
  },
  { path: 'Dataroom', component: HomeComponent,
    children: [
      { path: '', redirectTo: 'Glossary', pathMatch: 'full' },
      { path: 'Glossary', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'Lifetime-Metrics', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'Monthly-Metrics', component: HomeComponent },
      { path: 'SubsbyProduct-Table', component: HomeComponent },
      { path: 'SubsbyCountry', component: HomeComponent },
      { path: 'SubsbyProfession', component: HomeComponent },
      { path: 'ChurnRatebyMonth-Table', component: HomeComponent },
      { path: 'Conversion-Rates', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'LTVLifetimes', component: HomeComponent, canActivate: [AuthGuard] },
    ], canActivate: [AuthGuard], data: { showSideMenu: false, showDroomMenu: true, roles: [Role.Admin, Role.User, Role.Dataroom] }
  },
  { path: '404', component: PageNotFoundComponent, canActivate: [AuthGuard], data: { showTopIcons: false, showSideMenu: false, showSqlMenu: false } },

  //SfQueryComponent
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
