import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, Inject, AfterViewInit, ElementRef, Injectable, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterModule, ActivatedRoute} from "@angular/router";
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { MetricsService } from '../../metrics.service';
import { CustomService } from '../../_services/custom.service';
import { AuthenticationService } from '@app/_services';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as Tableau from 'tableau-api-js';
import {NgbAccordion} from '@ng-bootstrap/ng-bootstrap';

import {Observable, of, OperatorFunction} from 'rxjs';
//import {NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap} from 'rxjs/operators';

import alasql from 'alasql';
declare var $: any;
//import * as moment from 'moment';

const QUES_URL = environment.ApiPath+'autofilter.php';
//const QUES_URL = 'https://en.wikipedia.org/w/api.php';
const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*'
  }
});

@Injectable()
export class AutoFilterService {
  constructor(private http: HttpClient) {}

  search(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http
      .get<[any, string[]]>(QUES_URL, {params: PARAMS.set('search', term)}).pipe(
        map(response => response)
      );
  }
}

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
  providers: [AutoFilterService] // add NgbTypeaheadConfig to the component providers
})
export class AnalyticsComponent implements OnInit {
  title = 'Analytics - Analytics';
  @ViewChild('acc') acc: any;
  modalRef: any;
  queryForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: boolean = false;
  errorMsg = '';
  sqlTxt = '';
  showQueryBox: boolean = true;
  queryRes: any = []; 
  queryOrgRes: any = []; 
  queryOption: any; 
  driverLoading: any = []; 
  driverQueryRes: any = []; 
  driverQueryErr: any = []; 
  _object = Object;  
  inTableheight = '50vh';
  exportType: any;
  postSubmit: any;
  paramsSub:any;
	tableauViz: any;
	currMetrics: any;
  user: any;
  selSection: any;
  selMetrics = {'name':'MonthlyMetrics','hid':2, 'displayname':'Monthly Metrics'};
  rqRes = [];
  chartType = 'bar-vertical';
  yearData = [];
  yearSel = [];
  dropdownSettings:IDropdownSettings;
  objectKeys = Object.keys;
  
  selOpt = {
    'opt1':[{'id':'this_month', 'name':'This Month'},{'id':'last_month', 'name':'Last Month'},{'id':'mom', 'name':'MoM'},{'id':'current_year', 'name':'Current Year'},{'id':'last_year', 'name':'Last Year'},{'id':'yoy', 'name':'YoY'},{'id':'this_quarter', 'name':'This Quarter'},{'id':'last_quarter', 'name':'Last Quarter'},{'id':'qoq', 'name':'QoQ'},{'id':'lift_time', 'name':'Life Time'}],
    'opt2':[{'id':'bookings', 'name':'Bookings'},{'id':'tutor_packages', 'name':'Tutor Packages'},{'id':'new_purchases', 'name':'New Purchases'},{'id':'repurchases', 'name':'Repurchases'},{'id':'provisioned_hours', 'name':'Provisioned Hours'},{'id':'revenue', 'name':'Revenue'}],
    'opt3':[{'id':'aggregate', 'name':'Aggregate'},{'id':'daterange', 'name':'Date Range'},{'id':'region', 'name':'Region/Center'},{'id':'advisor', 'name':'Advisor'},{'id':'brand', 'name':'Brand'},{'id':'subject', 'name':'Subject'}],
    'opt4':[{'id':'LifetimeMetrics', 'name':'Lifetime Metrics'},{'id':'MonthlyMetrics', 'name':'Monthly Metrics'},{'id':'DailyMetrics', 'name':'Daily Metrics'},{'id':'EngagementDetail', 'name':'Engagement'},{'id':'Month1Retention', 'name':'Month 1 Retention'}],
  };
  selOpt1 = {
    'opt1':[{'id':'CY', 'name':'Current Year'},{'id':'LY', 'name':'Last Year'},{'id':'YoY', 'name':'YoY'},{'id':'MoM', 'name':'MoM'},{'id':'QoQ', 'name':'QoQ'}],
    'opt2':[{'id':'NS', 'name':'New Subscribers'},{'id':'TS', 'name':'Total Subscribers'},{'id':'CS', 'name':'Churned Subscribers'},{'id':'RE', 'name':'Revenue'},{'id':'AU', 'name':'Active Users'}],
    'opt3':[{'id':'AG', 'name':'Aggregate'},{'id':'BP', 'name':'By Product'},{'id':'BC', 'name':'By Channel'}],
    'opt4':[{'id':'LifetimeMetrics', 'name':'Lifetime Metrics'},{'id':'MonthlyMetrics', 'name':'Monthly Metrics'},{'id':'DailyMetrics', 'name':'Daily Metrics'},{'id':'EngagementDetail', 'name':'Engagement'},{'id':'Month1Retention', 'name':'Month 1 Retention'}],
  };


  chartData = [];
  driverChartData = [];
  view: any[] = [700, 600];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = false;
  xAxisLabel = 'Year';
  showYAxisLabel = false;
  yAxisLabel = 'Count';
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';


  public model: any;
  rep_ques = ["What is the current year’s revenue for the aggregate?", 
  "What is the last year’s revenue for the aggregate?",
  "What is the YOY revenue for the aggregate?",
  "What are the current year’s bookings for the aggregate?",
  "What are last year’s bookings for the aggregate?",
  "What are the YOY bookings for the aggregate?",
  "What are the current year’s new purchases for the aggregate?",
  "What are the last year’s new purchases for the aggregate?",
  "What are the YOY new purchases for the aggregate?",
  "What are the current year’s repurchases for the aggregate?",
  "What are the last year’s repurchases for the aggregate?",
  "What are the YOY repurchases for the aggregate?",
  "What is the current year’s revenue by the brand name?",
  "What is the last year’s revenue by the brand name?",
  "What is the YOY revenue by the brand name?",
  "What are the current year’s bookings by the brand name?",
  "What are last year’s bookings by the brand name?",
  "What are the YOY bookings by the brand name?",
  "What are the current year’s new purchases by the brand name?",
  "What are the last year’s new purchases by the brand name?",
  "What are the YOY new purchases by the brand name?",
  "What are the current year’s repurchases by the brand name?",
  "What are the last year’s repurchases by the brand name?",
  "What are the YOY repurchases by the brand name?"
  ];

  hip_ques = ["What is current month new subscription by aggregate?", 
  "What is last month new subscription by aggregate?",
  "What is current year new subscription by aggregate?",
  "What is last year new subscription by product?",
  "What is last quarter new subscription by product?",
  "Drivers: YOY new subscriptions by aggregate",
  "Drivers: MOM total subscriptions by aggregate",
  "Drivers: QOQ churned subscriptions by aggregate",
  "Drivers: MOM active user by aggregate",
  "Drivers: DOD active user by aggregate",
  "What is last year churned subscription by account type?",
  "What is last quarter churned subscription by account type?",
  "What is current year churned subscription by account type?",
  "What is last quarter renewed subscription by specialty?",
  "What is current year renewed subscription by specialty?",
  "What is last week active user by specialty?",
  "What is last quarter renewed subscription by specialty?",
  "What is last week total subscription by course title?",
  "What is current week total subscription by course title?",
  "What is current year total subscription by course title?",
  "What is last month total subscription by course title?"
  ];
  ques = [];
  rec_ques = [];
  question = '';
  searching = false;
  searchFailed = false;
  pgtype = '';

  rep_recQ = ["What is the current year’s revenue for the aggregate?", 
  "What is the last year’s revenue for the aggregate?",
  "What is the YOY revenue for the aggregate?",
  "What are the current year’s bookings for the aggregate?",
  "What are last year’s bookings for the aggregate?"
  ];

  hip_recQ = ["What is current month new subscription by aggregate?", 
  "What is last month new subscription by product?",
  "Drivers: YOY new subscriptions by aggregate",
  "What is current year churned subscription by account type?",
  "What is last quarter renewed subscription by specialty?"
  ];


  constructor(
    private formBuilder: FormBuilder, 
    private http: HttpClient,
    private modalService: NgbModal,
    private mService: MetricsService,
    private el: ElementRef, 
    private titleService: Title,
    private router: Router,
    private actRouter: ActivatedRoute,
    private cService: CustomService,
    private authService: AuthenticationService, 
    //private config: NgbTypeaheadConfig,
    private _service: AutoFilterService

  ) {
    //config.showHint = true;

    this.cService.onLoadScript(); 

    this.authService.user.subscribe(x => this.user = x);

    this.paramsSub = this.actRouter.params.subscribe(params => {
      //console.log(params);
      if(params.type != undefined && params.schema != undefined && params.table != undefined) {
        this.showTable({});
      }
    });

    //webGlObject.init();

  }
  
  ngOnInit(): void {
    this.pgtype = this.actRouter.snapshot.params.id;
    if(this.pgtype == 'Revolution-Prep') {
      this.ques = this.rep_ques;
      this.rec_ques = this.rep_recQ;
    } else if(this.pgtype == 'Hippo-Education') {      
      this.ques = this.hip_ques;
      this.rec_ques = this.hip_recQ;
    }
    this.callMenu(this.pgtype);
    
    this.titleService.setTitle( this.title );
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'year',
      textField: 'year',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      limitSelection: 2
    };
    this.getRandomColor(50);
    //$('#columnChart').hide();
    //$('#columnChart').css('visibility', 'hidden');
    
    
    /*this.router.navigate(['/SQLQuery'], {
      queryParams: {refresh: new Date().getTime()}
    }).then(() => {
      //window.location.reload();
    });
    this.router.navigateByUrl('/SQLQuery', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/SQLQuery']);
    }); */
    /*this.router.navigate(['/SQLQuery'])
    .then(() => {
      //window.location.reload();
    });*/
    
    if (this.mService.rsVars==undefined) {    
      this.mService.rsVars = this.mService.    
      invokeRSFunction.subscribe((sitem:any) => {
        this.showTable(sitem);    
      });    
    } 

  	this.queryForm = new FormGroup({
			opt1: new FormControl('current_year'),
			opt2: new FormControl('bookings'),
			opt3: new FormControl('aggregate'),
			opt4: new FormControl('LifetimeMetrics'),
			opt5: new FormControl('all'),
			opt6: new FormControl('2021')
		});
  }

  callMenu(type) {
    this.mService.onRSShowMenu(type);
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.ques.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  filterTxt(term) {
    return this.ques.filter(v => v.toLowerCase().includes(term.toLocaleLowerCase()));
  }

  onItemSelect(item: any) {
    console.log(item);
    //this.filterRes();
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  filterData(val) {
    return this.selOpt.opt1.filter(object => {
      return object.id == val;
    });
  }

  onSelect(event) {
    console.log(event);
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }

  onResize() { 
    this.view = [$(".resW").width(), 800];
    console.log(this.view);
  }

  recommend(ques) {
    this.question = ques;
    this.filterByQues();
  }

  getQuarterStrings(id) {
    var d = new Date();
    // quarter is 0-based here
    var quarter = Math.floor(d.getMonth() / 3),
        year = d.getFullYear();
    quarter -= id;
    
    if(quarter < 0) {
        var yearsChanged = Math.ceil(-quarter / 4);
        year -= yearsChanged;
        // Shift quarter back to a nonnegative number
        quarter += 4 * yearsChanged;
    }

    return "Q" + (quarter + 1) + "_" + year;
}

  showTable(row: any) {
    row = {'stype':this.actRouter.snapshot.params.type,'table_schema':this.actRouter.snapshot.params.schema,'table_name':this.actRouter.snapshot.params.table}
    
    if(row.stype == 'reset') {
      this.showQueryBox = true;
      this.queryRes = [];
      this.sqlTxt = '';
      this.errorMsg = '';
      this.inTableheight = '50vh';
    } else {
      this.showQueryBox = false;
      this.queryRes = [];
      this.sqlTxt = '';
      this.errorMsg = '';
      this.inTableheight = '85vh'
      this.loading = true;
      this.http.post<any>(environment.rsApiUrl, { 'tblschema':row.table_schema, 'tblname':row.table_name, 'ptype':row.stype }).subscribe(data => {
            
        if(data.status == 'success') {
          this.queryRes = data.output;
        } else {
          this.errorMsg = data.error;
        }
        //this.cd.detectChanges();
        //this.cd.markForCheck();
        this.loading = false;
      });
    }
      
    
  }

  get f() { return this.queryForm.controls; }

  filterByQues() {
    
    this.driverQueryRes = [];
    this.driverQueryErr = [];
    //this.rec_ques = [];

    this.errorMsg = '';
    this.sqlTxt = '';
    this.selSection = 'q1';
    if (this.question == "") {
      return;
    }
    this.loading = true;
    var obj = {
      q:this.question
    };
    this.postSubmit = this.http.post<any>(environment.rsApiUrl, { 'obj': obj,'ptype':'getques','company':this.pgtype }).subscribe(data => {        
      if(data.status == 'success' ) {
        
        this.queryOrgRes = data.output.result;
        this.queryOption = data.output.options;
        if(this.queryOption.recommendation != undefined) {
          this.rec_ques = this.queryOption.recommendation;
        }
        if(this.queryOption != null) {
          this.driverData(this.queryOption);
        }        
        this.queryRes = this.queryOrgRes;
        if(this.queryOrgRes.length > 0) {
          this.yearData = alasql('SELECT year FROM ? GROUP BY year', [this.queryOrgRes]);  
          console.log(this.yearData);
          if(this.queryOption.x_var == 'yoy' && this.yearData.length > 1) {
            this.yearSel = [
              {year:this.yearData[this.yearData.length - 2].year},
              {year:this.yearData[this.yearData.length - 1].year}
            ]
          } else {
            this.yearSel = [
              {year:this.yearData[this.yearData.length - 1].year}
            ]
          }
          this.chartType = this.queryOption.chart_type;
          this.filterRes(this.queryRes, 'init', this.queryOption, 0);
        }
        
      } else {
        this.errorMsg = data.error;
      }
      this.loading = false;      
    });
  }

  driverData(qopt) {
    this.driverLoading = [];
    // for(var i=0; i < Object.keys(qopt.driver_dict).length; i++) {
    //   this.driverLoading.push(true);
    //   this.driverQueryRes.push({'name':Object.keys(qopt.driver_dict)[i], 'question':qopt.driver_dict[Object.keys(qopt.driver_dict)[i]].question, 'ldata':[], 'ctype': '', 'cdata': [], 'order': 0, 'error': ''});
    // }
    if(Object.keys(qopt.driver_dict).length > 0) {
      var index = 0;
      Object.keys(qopt.driver_dict).forEach((key) => {
        this.driverLoading.push(true);
        this.driverQueryRes.push({'name':key, 'question':qopt.driver_dict[key].question, 'ldata':[], 'ctype': '', 'cdata': [], 'order': 0, 'error': ''});
        console.log(qopt.driver_dict[key]);
        console.log(index+' = '+key);
        this.getDriverData(qopt.driver_dict[key], index);
        index++
      })
    }
  }

  getDriverData(qdata, index) {
    this.postSubmit = this.http.post<any>(environment.rsApiUrl, { 'querytxt': qdata.query,'ptype':'sqlquery','company': this.pgtype }).subscribe(data => {        
      if(data.status == 'success' ) {
        console.log(index+' = '+data.output.length);
        this.driverQueryRes[index].ldata = data.output;
        if(data.output.length > 0) {
          var size = Object.keys(data.output[0]).length;
          if(size > 3) {
            var key = Object.keys(data.output[0]).slice(-2)[0];
          } else {
            var key = Object.keys(data.output[0]).slice(-1)[0];
          }
          this.driverQueryRes[index].order = Math.max.apply(Math, data.output.map(function(o) { return Math.abs(o[key]); }));
        }
        
        this.driverQueryErr.push('');
        this.filterRes(data.output, 'driver', qdata.chart, index);
      } else {
        this.driverQueryRes[index].ldata = [];
        this.driverQueryErr.push(data.error);
        this.driverQueryRes[index].error = data.error;
      }      
      this.driverLoading[index] = false;
      this.loading = false;
      if(this.driverLoading.filter(s => s == true).length == 0) {
        this.driverQueryRes = this.driverQueryRes.sort((a, b) => b.order - a.order);
        setTimeout(() => {
          this.toggleAccordio();
        }, 1000);
      }
      
    });
  }

  checkLoading() {
    return this.driverLoading.filter(s => s == true).length;
  }

  toggleAccordio() {
    acc: NgbAccordion;
    this.acc.activeIds = [0, 1, 2, 3, 4].map(i => `ngb-panel-${i}`);
  }

  onSubmit() {
    this.errorMsg = '';
    this.sqlTxt = '';
    this.selSection = 'q1';
    if (this.queryForm.invalid) {
      return;
    }
    this.loading = true;
    var obj = {
      t:this.f.opt1.value,
      m:this.f.opt2.value,
      d:this.f.opt3.value
    };
    this.postSubmit = this.http.post<any>(environment.rsApiUrl, { 'obj': obj,'ptype':'genquery' }).subscribe(data => {        
      if(data.status == 'success' ) {
        this.queryOrgRes = data.output;
        this.queryRes = this.queryOrgRes;
        if(this.queryOrgRes.length > 0) {
          this.yearData = alasql('SELECT year FROM ? GROUP BY year', [this.queryOrgRes]);          
          if(this.f.opt1.value == 'yoy') {
            this.yearSel = [
              {year:this.yearData[this.yearData.length - 2].year},
              {year:this.yearData[this.yearData.length - 1].year}
            ]
          } else {
            this.yearSel = [
              {year:this.yearData[this.yearData.length - 1].year}
            ]
          }
          this.filterRes(this.queryRes, 'init', {}, 0);
        }
        
      } else {
        this.errorMsg = data.error;
      }
      this.loading = false;      
    });
  }

  filterRes(cdata, type, opt, index) {
    // if(this.queryOption.z_var != 'aggregate') {
    //   var whr = 'WHERE 1 = 2 ';
    //   if(this.yearSel.length > 0) {      
    //     this.yearSel.forEach((v, k) => {
    //       whr += " OR year = '"+v.year+"'";
    //     });
    //   }
    //   this.queryRes = alasql('SELECT * FROM ? '+ whr, [this.queryOrgRes]);
    // }
        
    if(opt.chart_type == 'bar_chart' || opt.chart_type == 'pie_chart') {
      this.barChart(cdata, type, opt, index);
    } else if(opt.chart_type == 'grouped_bar_chart') {
      this.barGroupChart(cdata, type, opt, index);
    }


    
    //this.changeData(this.queryRes);
  }
  onSubmit1() {
        
      //$('#columnChart').hide();
      //$('#columnChart').css('visibility', 'hidden');
        this.selSection = 'q1';
		// stop here if form is invalid
        if (this.queryForm.invalid) {
            return;
        }
        //this.f.querytxt.value
        var whr = '';
        var fld = '';
        if(this.f.opt2.value == 'NS') {
          fld = 'new_subs';
        } else if(this.f.opt2.value == 'TS') {
          fld = 'total_subs';
        } else if(this.f.opt2.value == 'AU') {
          fld = 'active_subs';
        }
//         SELECT
// SUM(CASE WHEN date_trunc('qtr',month) = date_trunc('qtr',dateadd(qtr,-1,current_date)) THEN new_subs ELSE 0 END) as Current_Quarter,
// SUM(CASE WHEN date_trunc('qtr',month) = date_trunc('qtr',dateadd(qtr,-2,current_date)) THEN new_subs ELSE 0 END) as Last_Quarter
// FROM analytics.monthly_metrics
        var qry = "";
        var d1 = "";
        var d2 = "";
        if(this.f.opt1.value == 'CY') {
          qry = "select sum("+fld+") as cnt from analytics.monthly_metrics where date_trunc('year',month) = date_trunc('year',current_date)";
          //whr += "date_trunc('year',month) = date_trunc('year',current_date)";
        } else if(this.f.opt1.value == 'LY') {
          qry = "select sum("+fld+") as cnt from analytics.monthly_metrics where date_trunc('year',month) = date_trunc('year', (current_date  - interval '1 year'))";
          //whr += "date_trunc('year',month) = date_trunc('year', (current_date  - interval '1 year'))";
        } else if(this.f.opt1.value == 'YoY') {
          const current = new Date();
          const cyear1 = current.getFullYear();
          const cyear2 = current.getFullYear() - 1;
          d1 = cyear1.toString();
          d2 = cyear2.toString();

          qry = "SELECT SUM(CASE WHEN date_trunc('year', (current_date  - interval '1 year')) = date_trunc('year',month) THEN "+fld+" ELSE 0 END) as Year"+d2.toString()+", SUM(CASE WHEN date_trunc('year',current_date) = date_trunc('year',month) THEN "+fld+" ELSE 0  END) as Year"+d1.toString()+" FROM analytics.monthly_metrics";
          console.log(qry);
        } else if(this.f.opt1.value == 'MoM') {
          const current = new Date();
          current.setMonth(current.getMonth()-1);
          const cyear1 = new Date(current.setMonth(current.getMonth())).getFullYear();
          const m1 = current.toLocaleString('default', { month: 'long' });
          
          current.setMonth(current.getMonth()-1);
          const cyear2 = new Date(current.setMonth(current.getMonth())).getFullYear();
          const m2 = current.toLocaleString('default', { month: 'long' });
          d1 = m1.toLowerCase()+'_'+cyear1;
          d2 = m2.toLowerCase()+'_'+cyear2;
          qry = "SELECT SUM(CASE WHEN date_trunc('month', (current_date  - interval '2 month')) = date_trunc('month',month) THEN "+fld+" ELSE 0 END) as "+m2+"_"+cyear2+", SUM(CASE WHEN date_trunc('month',(current_date  - interval '1 month')) = date_trunc('month',month) THEN "+fld+" ELSE 0  END) as "+m1+"_"+cyear1+" FROM analytics.monthly_metrics";
          //whr += "date_trunc('year',month) = date_trunc('year', (current_date  - interval '1 year'))";
        } else if(this.f.opt1.value == 'QoQ') {
          d1 = this.getQuarterStrings(1).toLowerCase();
          d2 = this.getQuarterStrings(2).toLowerCase();
          qry = "SELECT SUM(CASE WHEN date_trunc('qtr',month) = date_trunc('qtr',dateadd(qtr,-1,current_date)) THEN "+fld+" ELSE 0 END) as "+d1+", SUM(CASE WHEN date_trunc('qtr',month) = date_trunc('qtr',dateadd(qtr,-2,current_date)) THEN "+fld+" ELSE 0 END) as "+d2+" FROM analytics.monthly_metrics";
          //whr += "date_trunc('year',month) = date_trunc('year', (current_date  - interval '1 year'))";
        }
        
        qry += whr;
        //return false;
        this.queryRes = [];
        this.sqlTxt = '';
        this.errorMsg = '';

        this.loading = true;
        this.postSubmit = this.http.post<any>(environment.rsApiUrl, { 'querytxt': qry,'ptype':'genquery' }).subscribe(data => {
        
        this.sqlTxt = qry;
		    if(data.status == 'success' ) {
          //this.queryForm.reset();
          this.queryRes = data.output;
          
    // var myVariable = "28 Jan 2014"
    // var makeDate = new Date(myVariable);
    // makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
    // console.log(makeDate);
    
          if(this.f.opt1.value == 'YoY') {
            this.queryRes[0]['percentage'] = (((parseInt(this.queryRes[0]['year'+d1]) - parseInt(this.queryRes[0]['year'+d2])) / parseInt(this.queryRes[0]['year'+d2])) * 100).toFixed(2);
          } else if(this.f.opt1.value == 'MoM' || this.f.opt1.value == 'QoQ') {
            this.queryRes[0]['percentage'] = (((parseInt(this.queryRes[0][d1]) - parseInt(this.queryRes[0][d2])) / parseInt(this.queryRes[0][d2])) * 100).toFixed(2);
          }
          console.log(this.queryRes);          
          this.changeData1(this.queryRes, d1, d2);
          //$('#columnChart').show();
          $('#columnChart').css('visibility', 'visible');
        } else {
          this.errorMsg = data.error;
        }
        this.loading = false;
        
        
		});
	};

  public barChart(chdata, type, opt, index): void {
    console.log(chdata);
    //this.chartData = [];
    var chartD = [];
    console.log(typeof opt.x_var);
    if(typeof opt.x_var == 'object') {
      opt.x_var.forEach((v, k) => {
        chdata.forEach((val, key) => {
          chartD.push({"name":v,"value":val[v], extra: {code: ''}});
        });
        //this.chartData.push({"name":v[opt.x_var],"value":v[opt.y_var], extra: {code: 'DE'}}); 
      });
    } else {
      chdata.forEach((v, k) => {
        chartD.push({"name":v[opt.x_var],"value":v[opt.y_var], extra: {code: ''}}); 
      });
    }
    if(type == 'init') {
      this.chartData = chartD;
    } else {      
      this.driverQueryRes[index].cdata = chartD;
      this.driverQueryRes[index].ctype = opt.chart_type;
    }
    console.log(chartD);
  }

  public barGroupChart(chdata, type, opt, index): void {
    console.log(chdata);
    //this.chartData = [];
    var chartD = [];
    // chdata.forEach((v, k) => {
    //   chartD.push({"name":v[opt.x_var],"value":v[opt.y_var], extra: {code: 'DE'}}); 
    // });
    
    var result = chdata.reduce( (r, a) => {
      //console.log(this.f.opt2.value.replace(' ','_').toLowerCase());
      var sname = a[opt.x_var];
      r[sname] = r[sname] || [];
      r[sname].push(a);
      return r;
    }, Object.create(null));

    console.log(result);
    var cdata = [];
    var sdata = [];
    var i = 0;
    var BreakException = {};
      try {
        Object.keys(result).some((key) => {
          sdata = [];
          if(typeof opt.y_var == 'object') {
            opt.y_var.forEach((v1, k1) => {
              result[key].forEach((v, k) => {
                sdata.push({"name":v1,"value":v[v1],"extra": {"type": "Year", "percentage": ''}});          
              });
              //chartD.push({"name":v[opt.x_var],"value":v[opt.y_var], extra: {code: 'DE'}}); 
            });
            
            const toTitleCase = str => str.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())
            var skey = toTitleCase(key.replace('_', ' '));
          } else {
            result[key].forEach((v, k) => {
              if(opt.x_var != null) {
                sdata.push({"name":v[opt.z_var],"value":v[opt.y_var],"extra": {"type": "Year", "percentage": ''}});          
              } else {
                sdata.push({"name":v[opt.x_var],"value":v[opt.y_var],"extra": {"type": "Year", "percentage": ''}});          
              }
              
            });
            const toTitleCase = str => str.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())
            var skey = toTitleCase(key.replace('_', ' '));
          }

          chartD.push({"name":skey,"series":sdata});
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
      if(type == 'init') {
        this.chartData = chartD;
      } else {      
        this.driverQueryRes[index].cdata = chartD;
        this.driverQueryRes[index].ctype = opt.chart_type;
      }
    console.log(chartD);
  }

  public changeData(chdata): void {
    console.log(chdata);
    this.chartData = [];
    var lbl = '';
    var whr = 'WHERE 1 = 2 ';
    if(this.yearSel.length > 0) {      
      this.yearSel.forEach((v, k) => {
        whr += " OR year = '"+v.year+"'";
      });
    }
    console.log(chdata);
    console.log('SELECT * FROM ? '+ whr);
    var data = [];
    if(this.queryOption.z_var == 'brand_name' || this.queryOption.z_var == 'subject_name') {
      data = alasql('SELECT * FROM ? '+ whr, [chdata]);
      console.log(data);
      //var result = data.reduce((h, obj) => Object.assign(h, { [obj.key]:( h[obj.key] || [] ).concat(obj) }), {});
      var result = data.reduce( (r, a) => {
        //console.log(this.f.opt2.value.replace(' ','_').toLowerCase());
        var sname = a[this.queryOption.z_var].replace(/ /g,"_").toLowerCase();
        r[sname] = r[sname] || [];
        r[sname].push(a);
        return r;
      }, Object.create(null));
      console.log('Testr: ',Object.keys(result).length);
      console.log('cnt: ',result);
      if(this.queryOption.x_var == 'current_year' || this.queryOption.x_var == 'last_year') {
        this.chartType = 'bar-vertical-2d';        
      } else {   
        //alert(Object.keys(result).length);
        // if(Object.keys(result).length < 3) {
        //   this.chartType = 'bar-vertical-2d';
        // } else {
        //   this.chartType = 'line-chart';
        // } 
        this.chartType = 'bar-vertical-2d';  
      }
      //console.log(result);
      var cdata = [];
      var sdata = [];
      var i = 0;
      var BreakException = {};
      try {
        Object.keys(result).some((key) => {
          sdata = [];
          result[key].forEach((v, k) => {
            var val = Math.floor(-1000 + Math.random() * 9000);
            sdata.push({"name":v.year,"value":v[this.queryOption.y_var],"extra": {"type": "Year", "percentage": ''}});          
          });
          const toTitleCase = str => str.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())
          var skey = toTitleCase(key.replace('_', ' '));

          this.chartData.push({"name":skey,"series":sdata}); 
          // if (key == "private_tutoring") {
          //   throw BreakException;
          // }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
      console.log(this.chartData);
      // result.forEach((value: string, key: string) => {
      //   console.log(key, value);
      // });
    } else { 
      this.chartType = 'bar-vertical';  
      chdata.forEach((v) => {
        this.chartData.push({"name":v.year,"value":v[this.f.opt2.value],"extra": {"type": "Year", "percentage": ''}});
      });
      console.log(this.chartData);
    }
    
  }

  public getRandomColor(cnt) {
    this.colorScheme.domain = [];
    for (var j = 0; j < cnt; j++) {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      this.colorScheme.domain.push(color);
    }
    //return color;
  }

  public changeData1(data, f1, f2): void {
    this.chartData = [];
    var lbl = '';
    if(this.f.opt1.value == 'CY') {
      lbl = 'Current Year';
      this.chartData.push({"name":lbl,"value":parseInt(data[0]['cnt']),"extra": {"type": "Year", "percentage": ''}});
    } else if(this.f.opt1.value == 'LY') {
      lbl = 'Last Year';
      this.chartData.push({"name":lbl,"value":parseInt(data[0]['cnt']),"extra": {"type": "Year", "percentage": ''}});
    } else if(this.f.opt1.value == 'YoY') {
      lbl = 'YoY';
      this.chartData.push({"name":f2,"value":parseInt(data[0]['year'+f2]),"extra": {"type": "Year", "percentage": ''}});
      this.chartData.push({'name':f1,"value":parseInt(data[0]['year'+f1]), "extra": {"type": "Year", "percentage": data[0]['percentage']}});  
      console.log(this.chartData);    
    } else if(this.f.opt1.value == 'MoM') {
      lbl = 'MoM';
      this.chartData.push({"name":f2.toUpperCase(),"value":parseInt(data[0][f2]),"extra": {"type": "Month", "percentage": ''}});
      this.chartData.push({'name':f1.toUpperCase(),"value":parseInt(data[0][f1]), "extra": {"type": "Month", "percentage": data[0]['percentage']}});   
    } else if(this.f.opt1.value == 'QoQ') {
      lbl = 'QoQ';
      console.log(f1);
      console.log(f2);
      this.chartData.push({"name":f2.toUpperCase(),"value":parseInt(data[0][f2]),"extra": {"type": "Quarter", "percentage": ''}});
      this.chartData.push({'name':f1.toUpperCase(),"value":parseInt(data[0][f1]), "extra": {"type": "Quarter", "percentage": data[0]['percentage']}}); 
    }
  }

  filterReport() {
    this.selSection = 'q2';
    console.log(this.f);
    this.http.post<any>(environment.apiUrl, { 'username': this.user.name,'ptype':'getTicket' }).subscribe(data => {
      if(data != -1) {
        this.initViz(data);
      }
    });
  }

  public initViz(t): void {
  	this.mService.emitChange(this.currMetrics);
		
		let mloader = this.el.nativeElement.querySelector("#loader");
  	mloader.classList.remove('d-none'); 
		let mview = this.el.nativeElement.querySelector("#viz-wrapper");
		mview.classList.add('d-none'); 


		const containerDiv = document.getElementById('tableauViz');
    var qry = '';
    var fld1 = '';
    var fld2 = ''; 

    if(this.f.opt6.value != '') {
      fld1 = this.f.opt6.value;
    }
    if(this.f.opt5.value != 'all' && this.f.opt5.value != '') {
      fld2 = this.f.opt6.value+this.f.opt5.value;
    }

     
		
		const vizUrl = environment.tableauUrl+'/trusted/'+t+'/views/Hippo-AnalyticsDashboard/'+this.f.opt4.value+'?:size=1232,850&:embed=y&:showVizHome=n&:bootstrapWhenNotified=y&:tabs=n&:toolbar=n&:apiID=host2'+qry;
    console.log(vizUrl);
    var options = {};
    if(this.f.opt4.value == 'DailyMetrics') {
      options = {
        hideTabs: true,
        width: '100%',
        height: '850px',
        "YEAR(Date)": fld1,
        "MY(Date)": fld2,
        onFirstInteractive: function() {
          mloader.classList.add('d-none'); 
          mview.classList.remove('d-none');
          // The viz is now ready and can be safely used.
        }
      };
    } else {
      options = {
        hideTabs: true,
        width: '100%',
        height: '850px',
        "YEAR(Month)": fld1,
        "MY(Month)": fld2,
        onFirstInteractive: function() {
          mloader.classList.add('d-none'); 
          mview.classList.remove('d-none');
          // The viz is now ready and can be safely used.
        }
      };
    }
    console.log(options);

		if (this.tableauViz) { // If a viz object exists, delete it.
			this.tableauViz.dispose();
		}

		//this.tableauViz = new tableau.Viz(placeholderDiv, url, options);	
		this.tableauViz = new Tableau.Viz(containerDiv, vizUrl, options);
  }


  onSubmitCancel() {
    this.postSubmit.unsubscribe();
    this.showQueryBox = true;
    this.queryRes = [];
    this.sqlTxt = '';
    this.errorMsg = '';
    this.loading = false;
  }

  exportData(etype) {
    if(etype == 'csv') {
      alasql.promise('SELECT * INTO CSV("export_data.csv", {asstring:true, headers:true, separator:","}) FROM ?',[this.queryRes])
      .then(function(){
           console.log('Data saved');
      }).catch(function(err){
           console.log('Error:', err);
      });
    } else if(etype == 'xlsx') {
      alasql.promise('SELECT * INTO XLSX("export_data.xlsx",{headers:true}) FROM ? ',[this.queryRes])
      .then(function(){
           console.log('Data saved');
      }).catch(function(err){
           console.log('Error:', err);
      });
    } else if(etype == 'txt') {
      alasql.promise('SELECT user_id,first_name INTO TXT("export_data.txt") FROM ? ',[this.queryRes])
      .then(function(){
           console.log('Data saved');
      }).catch(function(err){
           console.log('Error:', err);
      });
    }
  }

  exportD(content, etype) {
    this.exportType = etype;
    this.modalRef = this.modalService.open(content, { centered: true }).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //this.closeResult = `Dismissed`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
