<main class="app-content">
   <div class="app-title">
      <div>
         <h1><i class="fa {{pgIcon}}"></i> {{currTitle}}</h1>
      </div>
      <ul class="app-breadcrumb breadcrumb">
         <li class="breadcrumb-item"></li>
      </ul>
   </div>
   <div class="row" *ngIf="currPath == 'Settings'">
      <div class="col-md-12">
         <div class="tile">
            <div class="embed-responsive">
               <div class="container"></div>
               ,
               <div class="container">
                  <form [formGroup]="settingForm" (ngSubmit)="onSubmit()">
	                  <ng-wizard [config]="config" (stepChanged)="stepChanged($event)">  
		                  <ng-wizard-step [title]="'Redshift Credentials'" [description]="'1'"
		                  [canEnter]="isValidTypeBoolean" [canExit]="isValidFunctionReturnsBoolean.bind(this)">
		                  <div class="setup-content" id="step-1">
		                     <div class="col-xs-12 col-md-offset-12">
		                        <div class="col-md-12" style="margin-top:20px;">
		                           <h4> Redshift Credentials</h4>
		                           <div class="form-row">
		                              <div class="form-group col-md-6">
		                                 <label for="rshost" class="control-label">Host</label>
		                                 <input type="text" class="form-control" id="rshost" placeholder="Host..." name="rshost" autocomplete="off" formControlName="rshost" required="required">
		                              </div>
		                              <div class="form-group col-md-6">
		                                 <label for="rsport" class="control-label">Password</label>
		                                 <input type="text" class="form-control" id="rsport" placeholder="Port..." name="rsport" autocomplete="off" formControlName="rsport" required="required">
		                              </div>
		                           </div>
		                           <div class="form-group">
		                              <label for="rsdbname" class="control-label">Database</label>
		                              <input type="text" class="form-control" id="rsdbname" placeholder="Database..." name="rsdbname" autocomplete="off" formControlName="rsdbname" required="required">
		                           </div>
		                           <div class="form-row">
		                              <div class="form-group col-md-6">
		                                 <label for="rsuser" class="control-label">User Name</label>
		                                 <input type="text" class="form-control" id="rsuser" placeholder="Username..." name="rsuser" autocomplete="off" formControlName="rsuser" required="required">
		                              </div>
		                              <div class="form-group col-md-6">
		                                 <label for="rspassword" class="control-label">Password</label>
		                                 <input type="password" class="form-control" id="rspassword" placeholder="Password..." name="rspassword" autocomplete="off" formControlName="rspassword">
		                              </div>
		                           </div>
		                        </div>
		                     </div>
		                  </div>
		                  </ng-wizard-step>
		                  <ng-wizard-step [title]="'Tableau Credentials'" [description]="'2'" [canExit]="isValidFunctionReturnsBoolean.bind(this)">
		                  <div class="setup-content" id="step-2">
		                     <div class="col-xs-6 col-md-offset-3">
		                        <div class="col-md-12" style="margin-top:20px;">
		                           <h4> Tableau Credentials</h4>
		                           <div class="form-group">
		                              <label for="thost" class="control-label">Host</label>
		                              <input type="text" class="form-control" id="thost" placeholder="Host..." name="thost" formControlName="thost" required="required">
		                           </div>
		                           <div class="form-group">
		                              <label for="tuser" class="control-label">User Name</label>
		                              <input type="text" class="form-control" id="tuser" placeholder="Username..." name="tuser" formControlName="tuser" required="required">
		                           </div>
		                           <div class="form-group">
		                              <label for="tpassword" class="control-label">Password</label>
		                              <input type="password" class="form-control" id="tpassword" placeholder="Password..." name="tpassword" formControlName="tpassword">
		                           </div>
		                        </div>
		                     </div>
		                  </div>
		                  </ng-wizard-step>
		                  <ng-wizard-step [title]="'Site Configuration'" [description]="'3'"
		                  [canEnter]="isValidFunctionReturnsObservable.bind(this)" [canExit]="isValidFunctionReturnsBoolean.bind(this)">
		                  <div class="setup-content" id="step-3">
		                     <div class="col-xs-6 col-md-offset-3">
		                        <div class="col-md-12" style="margin-top:20px;">
		                           <h4> Site Configuration</h4>
		                           <div class="form-group">
		                              <label for="sess_time" class="control-label">Session Timeout</label>
		                              <input type="number" class="form-control" id="sess_time" placeholder="Session Timeout..." name="sess_time" formControlName="sess_time" required="required">
		                           </div>
		                           <div class="form-group">
		                              <label for="sess_stay" class="control-label">Session Stay</label>
		                              <input type="number" class="form-control" id="sess_stay" placeholder="Session Stay..." name="sess_stay" formControlName="sess_stay" required="required">
		                           </div>
		                        </div>
		                     </div>
		                  </div>
		                  </ng-wizard-step>
	                  </ng-wizard>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>


   <!-- User List -->
   <div class="row" *ngIf="currPath == 'Users'">
      <div class="col-md-12">
         <div class="tile">
            <div class="embed-responsive">
               <div class="">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered table-hover">
				   <thead>
				      <tr>
				        <!-- <th>&nbsp;</th> -->
				        <th>Display Name</th>
						<th>Username</th>
						<th>Site Role</th>
						<th>Last Signed In</th>
				      </tr>
				   </thead>
				   <tbody *ngIf="isLoading">
				   		<tr>
				   			<td colspan="4">
				   				<div id="loader" class="fa-3x text-center text-black">
							      <i class="fas fa-spinner fa-spin"></i>
							   	</div>
				   			</td>
				   		</tr>
				   </tbody>
				   <tbody *ngIf="queryRes.user.length == 0">
				   		<tr>
				   			<td colspan="4">
				   				<div id="loader" class="fa-2x text-center text-black">
							      No Records
							   	</div>
				   			</td>
				   		</tr>
				   </tbody>
				   <tbody *ngIf="queryRes.user.length > 0">
				      <tr *ngFor="let urow of queryRes.user">
				         <!-- <td><input type="checkbox"></td> -->
				         <td>
				            {{urow.fullName}} 
				            <!-- <div class="dropdown show" style="float:right; display:inline-block">
				               <a class="dropdown-toggle" href="#" style="font-size:1.3em;" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				               <i class="fa fa-ellipsis-h"></i>
				               </a>
				               <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
				                  <a class="dropdown-item" href="#">Group Membership</a>
				                  <a class="dropdown-item" href="#">Site Role</a>
				                  <a class="dropdown-item" href="#">Remove</a>
				                  <a class="dropdown-item" href="#">Force Password Update</a>
				                  <a class="dropdown-item" href="#">Unlock Account Access</a>
				               </div>
				            </div> -->
				         </td>
				         <td>{{urow.name}}</td>
				         <td>{{urow.siteRole}}</td>
				         <td>{{urow.lastLogin | date:'MMM d, y, h:mm a'}}</td>
				      </tr>
				   </tbody>
				</table>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Group List -->
   <div class="row" *ngIf="currPath == 'Groups'">
      <div class="col-md-12">
         <div class="tile">
            <div class="embed-responsive">
               <div class="">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered table-hover">
				   <thead>
				      <tr>
				        <!-- <th>&nbsp;</th> -->
				        <th>Display Name</th>
				      </tr>
				   </thead>
				   <tbody *ngIf="isLoading">
				   		<tr>
				   			<td colspan="4">
				   				<div id="loader" class="fa-3x text-center text-black">
							      <i class="fas fa-spinner fa-spin"></i>
							   	</div>
				   			</td>
				   		</tr>
				   </tbody>
				   <tbody *ngIf="queryRes.group.length == 0">
				   		<tr>
				   			<td colspan="4">
				   				<div id="loader" class="fa-2x text-center text-black">
							      No Records
							   	</div>
				   			</td>
				   		</tr>
				   </tbody>
				   <tbody *ngIf="queryRes.group.length > 0">
				      <tr *ngFor="let grow of queryRes.group">
				         <!-- <td><input type="checkbox"></td> -->
				         <td>
				            {{grow.name}} 
				         </td>
				      </tr>
				   </tbody>
				</table>
               </div>
            </div>
         </div>
      </div>
   </div>

   <!-- Project List -->
   	<div class="row" *ngIf="currPath == 'Projects'">
   		<div id="loader" class="fa-3x text-center text-black w-100 p-3" *ngIf="isLoading">
	      <i class="fas fa-spinner fa-spin"></i>
	   	</div>
		<div class="col-md-4" *ngFor="let prow of queryRes.project">
		   <div class="card" >
		      <a href="javascript://" [routerLink]="['/Admin/Workbooks',prow.id]" style="z-index:9"><img class="card-img-top" src="assets/images/project-thumb-3.png" alt="Card image cap" style="border:1px solid #ccc;"></a>
		      <div class="card-body">
		         <div>
		            <h5>
		               {{ prow.name }} 
		               <!-- <span class="pull-right">
		                  <div class="dropdown show" style="float:right; display:inline-block">
		                     <a class="dropdown-toggle" href="#" style="font-size:1.3em;" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
		                     <i class="fa fa-ellipsis-h"></i>
		                     </a>
		                     <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
		                        <a class="dropdown-item" href="#">Rename...</a>
		                        <a class="dropdown-item" href="#">Move...</a>
		                        <a class="dropdown-item" href="#">Permissions...</a>
		                        <a class="dropdown-item" href="#">Change Owner...</a>
		                        <a class="dropdown-item" href="#">Delete...</a>
		                     </div>
		                  </div>
		               </span> -->
		            </h5>
		         </div>
		         <p class="card-text">{{prow.description}}</p>
		      </div>
		   </div>
		</div>
   	</div>

   <!-- Workbook List --> <!-- {{ wrow.webpageUrl | replace : '#' : 'vizportal/api/rest/v1' }}/thumbnail?1602623856343 -->
   	<div class="row" *ngIf="currPath == 'Workbooks'">
   		<div id="loader" class="fa-3x text-center text-black w-100 p-3" *ngIf="isLoading">
	      <i class="fas fa-spinner fa-spin"></i>
	   	</div>
		<div class="col-md-4" *ngFor="let wrow of queryRes">
		   <div class="card" >
		   		<!-- <div class="overlay"><div class="overlay-content"><i class="fas fa-spinner fa-spin"></i></div></div> -->
		      <a href="javascript://" [routerLink]="['/Admin/Views',wrow.id]" style="z-index:9">
		      	<!-- <img class="card-img-top" src="assets/images/project-thumb-3.png" alt="Card image cap" style="border:1px solid #ccc;"> -->
		      	<img [src]="'http://localhost/analytics-portalv1/api/getimage.php?ptype=w&&wid='+wrow.id | authImage | async" class="card-img-top" alt="Card image cap" style="border:1px solid #ccc;" onerror="this.src='assets/images/project-thumb-3.png'" height="240px">
		      </a>
		      <div style="border:1px solid #ccc; padding:5px; margin-left:4px; margin-right:4px; margin-top:-7px; z-index:2; background-color:#ffffff;"></div>
		      <div style="border:1px solid #ccc; padding:5px; margin-left:7px; margin-right:7px; margin-top:-7px; z-index:1; background-color:#ffffff;"></div>
		      <div class="card-body">
		         <div>
		            <h5>
		               {{ wrow.name }} 
		               <!-- <span class="pull-right">
		                  <div class="dropdown show" style="float:right; display:inline-block">
		                     <a class="dropdown-toggle" href="#" style="font-size:1.3em;" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
		                     <i class="fa fa-ellipsis-h"></i>
		                     </a>
		                     <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="z-index:9999">
		                        <a class="dropdown-item" href="#">Edit Workbook</a>
		                        <div class="dropdown-divider"></div>
		                        <a class="dropdown-item" href="#">Download</a>
		                        <div class="dropdown-divider"></div>
		                        <a class="dropdown-item" href="#">Tag...</a>
		                        <a class="dropdown-item" href="#">Rename...</a>
		                        <a class="dropdown-item" href="#">Move...</a>
		                        <a class="dropdown-item" href="#">Permissions...</a>
		                        <a class="dropdown-item" href="#">Change owner...</a>
		                        <a class="dropdown-item" href="#">Change owner...</a>
		                        <div class="dropdown-divider"></div>
		                        <a class="dropdown-item" href="#">Tabbed view...</a>
		                        <a class="dropdown-item" href="#">Refresh Extracts...</a>
		                        <div class="dropdown-divider"></div>
		                        <a class="dropdown-item" href="#">Revision History...</a>
		                        <a class="dropdown-item" href="#">Delete...</a>
		                     </div>
		                  </div>
		               </span> -->
		            </h5>
		         </div>
		      </div>
		   </div>
		</div>
   	</div>

   	<!-- View List -->
   	<div class="row" *ngIf="currPath == 'Views'">
   		<div id="loader" class="fa-3x text-center text-black w-100 p-3" *ngIf="isLoading">
	      <i class="fas fa-spinner fa-spin"></i>
	   	</div>
		<div class="col-md-3" style="padding-bottom: 10px;" *ngFor="let vrow of queryRes.view">
		   <div class="card" > <!-- src="assets/images/project-thumb-3.png" -->
		      <a href="javascript://" style="z-index:9"><img [src]="'http://localhost/analytics-portalv1/api/getimage.php?ptype=v&wid='+paramID+'&vid='+vrow.id | authImage | async" class="card-img-top" alt="Card image cap" style="border:1px solid #ccc;" onerror="this.src='assets/images/project-thumb-2.png'" height="220px"></a>
		      <div class="card-body">
		         <div>
		            <h5>
		               {{ vrow.name }} 
		               <!-- <span class="pull-right">
		                  <div class="dropdown show" style="float:right; display:inline-block">
		                     <a class="dropdown-toggle" href="#" style="font-size:1.3em;" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
		                     <i class="fa fa-ellipsis-h"></i>
		                     </a>
		                     <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
		                        <a class="dropdown-item" href="#">Rename...</a>
		                        <a class="dropdown-item" href="#">Move...</a>
		                        <a class="dropdown-item" href="#">Permissions...</a>
		                        <a class="dropdown-item" href="#">Change Owner...</a>
		                        <a class="dropdown-item" href="#">Delete...</a>
		                     </div>
		                  </div>
		               </span> -->
		            </h5>
		         </div>
		      </div>
		   </div>
		</div>
   	</div>


</main>